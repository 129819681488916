import { Component } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'timecards-menu',
  template: `
    <span class="mr-1" *ngIf="'Pages.Timecards' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='timecards-dashboard' || headerService.display_sub_page===''}" (click)="headerService.displaySubPage('timecards-dashboard')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'Dashboard' | localize }}
        </button>
    </span>
    <span class="mr-1" *ngIf="'Pages.Timecards.Legacy.List' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='timecards-legacy-list'}" (click)="headerService.displaySubPage('timecards-legacy-list')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'List' | localize }}
        </button>
    </span>
    <span class="mr-1" *ngIf="'Pages.Timecards.Splits' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='timecards-splits'}" (click)="headerService.displaySubPage('timecards-splits')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'Splits' | localize }}
        </button>
    </span>
  `,
  styles: ``
})
export class TimecardsMenuComponent extends AppComponentBase {

}
