<div class="content">
    <div class="display-group">
      <span class="label">Address:</span> {{address?.streetAddress}}
    </div>  
    <div class="display-group">
      <span class="label"></span> {{address?.city}}
    </div>  
    <div class="display-group">
      <span class="label"></span> {{address?.state}}
    </div>  
    <div class="display-group">
      <span class="label"></span> {{address?.postalCode}}
    </div>  
  </div>