import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CandidateService } from './candidate.service';
import { CommonModule } from '@angular/common';
import { CandidateDetailComponent } from './candidate-detail.component';
import { CandidateChecklistComponent } from "./candidate-checklist.component";
import { DxTabPanelModule, DxTabsModule } from 'devextreme-angular';
import { CandidateResumeComponent } from './candidate-resume.component';

@Component({
  selector: 'dcar-candidate-detail-tabs',
  standalone: true,
  imports: [CommonModule, CandidateDetailComponent, CandidateChecklistComponent, CandidateResumeComponent, DxTabsModule, DxTabPanelModule],
  templateUrl: './candidate-detail-tabs.component.html',
  styleUrl: './candidate-detail-tabs.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CandidateDetailTabsComponent extends AppComponentBase {
  @ViewChild('candidateDetailComponent') candidateDetailComponent:CandidateDetailComponent;
  //@Output() passCloseUpdatePopup = new EventEmitter<boolean>();
  //controlsDisabled = true;

  constructor(
    injector: Injector,
    public _candidateService: CandidateService
  ) {
    super(injector);
  }

  toggleDiabled() {
    this.candidateDetailComponent.toggleDiabled();
  }
  
  edit() {
    this.candidateDetailComponent.edit();
  }

  updateCandidate() {
    this.candidateDetailComponent.updateCandidate();
  }

  requestTsNumber(e) {
    this.candidateDetailComponent.requestTsNumber(e);
  }

  

}
