import { Directive, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[disableControl]'
})

// export class DisableControlDirective {

//   @Input() set disableControl( condition : boolean ) {
//     const action = condition ? 'disable' : 'enable';
//     this.ngControl.control[action]();
//   }
//   constructor( private ngControl : NgControl ) {
//   }
// }

export class DisableControlDirective implements OnInit {
  @Input() set disableControl(condition: boolean) {
    if (this.disabled !== undefined) {
      this.toggleControl(condition);
    }
    this.disabled = condition;
  }

  disabled: boolean;

  constructor(private ngControl: NgControl) {}

  ngOnInit() {
    this.toggleControl(this.disabled);
  }

  toggleControl(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    this.ngControl.control[action]();
  }
}