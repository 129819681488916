<div [@routerTransition]>
    <div class="n-container">
        <div class="p-d-flex p-flex-wrap">
            <div class="grid">
                <div *ngFor="let cat of reportCategories" class="col-2">
                    <div class="p-mr-2 p-mb-2">
                        <div class="card card-custom gutter-b">
                            <div class="card-header align-items-center border-0">
                                <h3 class="card-title align-items-start flex-column">
                                    <span class="font-weight-bolder text-dark">
                                        {{ cat.report_category }}
                                    </span>
                                </h3>
                            </div>
                            <div class="card-body">
                                <div class="grid">
                                    <ng-container *ngFor="let rep of reportList">
                                        <div
                                            *ngIf="rep.r_report_category_id == cat.r_report_category_id"
                                            class="col-12 rpt-item"
                                        >
                                            <ng-container *ngIf="rep.report_status == 'inactive'">
                                                <fa-icon
                                                    [icon]="icon.farBan"
                                                    class="n-color-red"
                                                    style="font-size: 16px"
                                                ></fa-icon>
                                                &nbsp;&nbsp;
                                                <span class="n-color-blue">{{ rep.report_name }}</span>
                                            </ng-container>
                                            <ng-container *ngIf="rep.report_status == 'active'">
                                                <a class="n-abtn" (click)="setReportName(rep.report_code)">
                                                    <fa-icon
                                                        [icon]="icon.farFileInvoice"
                                                        class="n-color-green"
                                                        style="font-size: 16px"
                                                    ></fa-icon>
                                                </a>
                                                &nbsp;&nbsp;
                                                <a class="n-abtn n-color-blue" (click)="setReportName(rep.report_code)">
                                                    {{ rep.report_name }}
                                                </a>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
