/**
 * @description : This base service provides angular get and post functionality to other pages
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AppConsts } from '@shared/AppConsts';


@Injectable({
    providedIn: 'root'
})
export class HttpBaseService {
    env = '_STAGE';
    //env = '';
    constructor(public http: HttpClient) {
    }

    /**
     * Makes the http get request call using the http client
     * @params {string} url
     * @params {HttpParams} urlParams
     */
    public get(url: string, urlParams?: HttpParams): Observable<any> {
        return this.http.get(environment.remoteApiBaseUrl + url, { params: urlParams, headers: environment.remoteApiHeaders });
    }
    
    public getBlob(url: string, urlParams?: HttpParams): Observable<any> {
        return this.http.get(environment.remoteApiBaseUrl + url, { params: urlParams, headers: environment.azureBlobHeaders, responseType: 'blob' });
    }
    
    // public getImage(url: string, urlParams?: HttpParams): Observable<any> {
    //     return this.http.get(environment.remoteApiBaseUrl + url, { params: urlParams, headers: environment.azureBlobHeaders, responseType: 'blob' });
    // }
    
    // public getPdf(url: string, urlParams?: HttpParams): Observable<any> {
    //     return this.http.get(environment.remoteApiBaseUrl + url, { params: urlParams, headers: environment.azurePdfHeaders, responseType: 'text' });
    // }
    // public getDoc(url: string, urlParams?: HttpParams): Observable<any> {
    //     return this.http.get(environment.remoteApiBaseUrl + url, { params: urlParams, headers: environment.azureDocHeaders, responseType: 'text' });
    // }

    public delete(url: string, urlParams?: HttpParams): Observable<any> {
        return this.http.delete(environment.remoteApiBaseUrl + url, { params: urlParams, headers: environment.remoteApiHeaders });
    }

    public post(url: string, body: any): Observable<any> {
        return this.http.post(environment.remoteApiBaseUrl + url, body, { headers: environment.remoteApiHeaders });
    }
    
    public postMultiPart(url: string, body: any): Observable<any> {
        return this.http.post(environment.remoteApiBaseUrl + url, body, { headers: environment.multiPartApiHeaders });
    }

    public patch(url: string, body: any): Observable<any> {
        return this.http.patch(environment.remoteApiBaseUrl + url, body, { headers: environment.remoteApiHeaders });
    }

    public postReturnText(url: string, body: any): Observable<any> {
        return this.http.post(environment.remoteApiBaseUrl + url, body, { headers: environment.remoteApiHeaders, responseType: 'text' });
    }

    public put(url: string, body: any): Observable<any> {
        return this.http.put(environment.remoteApiBaseUrl + url, body, { headers: environment.remoteApiHeaders });
    }

    public postServiceBus(url: string, body: any): Observable<any> {
        return this.http.post(environment.remoteApiBaseUrl + url, body, { headers: environment.remoteApiHeaders, responseType: 'blob' });
    }

    public postFile(url: string, body: any): Observable<any> {
        return this.http.post(environment.filemanagerapi + url, body, { headers: environment.filemanagerApiHeaders });
    }
}
