import { Component } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'candidates-menu',
  template: `
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='candidates-dashboard' || headerService.display_sub_page===''}" (click)="headerService.displaySubPage('candidates-dashboard')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'Dashboard' | localize }}
        </button>
    </span>
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='candidates-search' || headerService.display_sub_page===''}" (click)="headerService.displaySubPage('candidates-search')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'Search' | localize }}
        </button>
    </span>
  `,
  styles: ``
})
export class CandidatesMenuComponent extends AppComponentBase {

}
