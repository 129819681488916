<div class="n-container">
    <stimulsoft-viewer-angular 

    [requestUrl]="'https://localhost:7249/viewer/{action}'" [action]="'InitViewer'" [height]="'95vh'"
    >
    
    </stimulsoft-viewer-angular>
    <!-- <dx-data-grid 
        id="pivotGrid" 
        [dataSource]="dataSource"
        columnAutoWidth="true"
        (onExporting)="onExporting($event)"
        >
        <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
    </dx-data-grid> -->
</div>