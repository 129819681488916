import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Injector } from '@angular/core';
import { DxDataGridModule } from 'devextreme-angular';
import { CandidateTechPackList } from './_models/candidate-tech-pack-list.interface';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CandidateService } from './candidate.service';

@Component({
  selector: 'dcar-candidate-tech-pack',
  standalone: true,
  imports: [CommonModule, DxDataGridModule],
  templateUrl: './candidate-tech-pack.component.html',
  styleUrl: './candidate-tech-pack.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CandidateTechPackComponent extends AppComponentBase {
  
  ds_recordList: CandidateTechPackList[] = [];
  dataGridPlaceHolder = 'No tech packs found';

  constructor(
    injector: Injector,
    public _candidateService: CandidateService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getCandidateTechPackList();
  }

  getCandidateTechPackList() {
    this._candidateService.getCandidateTechPackList().subscribe((result) => {
      this.ds_recordList = result;
    });
  }

  openDrawer() {
    this.signalService.openCandidateDetailPopup.set(true);
  }
}
