import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CandidateList } from './_models/candidate-list.interface';
import { HttpBaseService } from '@app/shared/_services/http-base.service';
import { CandidateTechPackList } from './_models/candidate-tech-pack-list.interface';
import { CandidateDetail } from './_models/candidate-detail.interface';
//import { FormBuilder, FormGroup } from '@angular/forms';
//import { resume } from '@app/_databases/_entities/resume.entity';
//import { CandidateChecklist } from './_models/candidate-checklist.interface';
//import { DataService } from '@app/shared/_services/data.service';

@Injectable({
  providedIn: 'root'
})
export class CandidateService {

  controlsDisabled = true;
  isAddPopupVisible = false;
  isUpdatePopupVisible = false;
  isRequestTechPackPopupVisible = false;
  isConvertToTsPopupVisible = false;
  

  constructor(
    private _httpBaseService: HttpBaseService,
  ) {}

  getCandidateList(): Observable<CandidateList[]> {
    return this._httpBaseService.get(`dcar/get-candidates`);
  }
  
  getCandidateTechPackList(): Observable<CandidateTechPackList[]> {
    return this._httpBaseService.get(`dcar/get-candidate-tech-pack-list`);
  }

  updateCandidate(selectedCandidate: CandidateDetail): Observable<any> {
    return this._httpBaseService.post(`dcar/save-candidate`, selectedCandidate);
  }

  createCandidate(selectedCandidate: CandidateDetail): Observable<any> {
    return this._httpBaseService.post(`dcar/create-candidate`, selectedCandidate);
  }

  requestTechPack(candidate_id: number, dcar_user_id: number, delivery_type: string): Observable<any> {
    return this._httpBaseService.post(`dcar/request-tech-pack`, {candidate_id, dcar_user_id, delivery_type});
  }

  closeAddPopup(event) {
    if (event) {
      this.isAddPopupVisible = false;
    }
  }

  closeUpdatePopup(event) {
    if (event) {
      this.isUpdatePopupVisible = false;
    }
  }
  
}