<!-- <div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">
        
        <div [class]="containerClass">
            <div class="card card-custom"> -->
                <!-- <div class="card-body"> -->
                    <!-- <div class="n-container"> -->
                        <!-- <div>
                            <button class="btn btn-sm btn-primary" (click)="reportUrl='R001'">R001</button>
                            <button class="btn btn-sm btn-primary" (click)="reportUrl='R002'">R002</button>
                        </div> -->
                        <dx-report-viewer [reportUrl]="reportUrl" height="90vh">
                            <dxrv-request-options [invokeAction]="invokeAction" [host]="hostUrl"></dxrv-request-options>
                        </dx-report-viewer>
                    <!-- </div> -->
                <!-- </div> -->
            <!-- </div>
        </div>
    </div>
</div> -->