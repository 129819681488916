import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(private dataService: DataService) { }

  get createOrUpdateCandidateButtonDisabled(): boolean {
    if (
      this.dataService.selectedCandidate.first_name && 
      this.dataService.selectedCandidate.last_name && 
      this.dataService.selectedCandidate.email && 
      this.dataService.selectedCandidate.add_1 &&
      this.dataService.selectedCandidate.city &&
      this.dataService.selectedCandidate.country &&
      this.dataService.selectedCandidate.state &&
      this.dataService.selectedCandidate.status_id

    ) {
      return false;
    } else {
      return true;
    }
  }

  
}
