<!-- <sl-tab-group>
    <sl-tab slot="nav" panel="main">Main Detail</sl-tab>
    <sl-tab slot="nav" panel="checklist">Checklist</sl-tab>
    <sl-tab slot="nav" panel="com-log">Com Log</sl-tab>

    <sl-tab-panel name="main">
        <dcar-candidate-detail #candidateDetailComponent></dcar-candidate-detail>
    </sl-tab-panel>
    <sl-tab-panel name="checklist">
        <dcar-candidate-checklist #candidateChecklistComponent></dcar-candidate-checklist>
    </sl-tab-panel>
    <sl-tab-panel name="com-log">
        <dcar-candidate-com-log #candidateComLogComponent></dcar-candidate-com-log>
    </sl-tab-panel>
</sl-tab-group> -->

<dx-tab-panel width="100%" [animationEnabled]="true">
    <dxi-item title="Main Detail" icon="far fa-user">
        <dcar-candidate-detail #candidateDetailComponent></dcar-candidate-detail>
    </dxi-item>
    <dxi-item title="Resume" icon="far fa-file-alt">
        <dcar-candidate-resume #candidateResumeComponent></dcar-candidate-resume>
    </dxi-item>
    <dxi-item [disabled]="!dataService.selectedCandidate.tech_pack" title="Checklist" icon="far fa-list-alt">
        <dcar-candidate-checklist #candidateChecklistComponent></dcar-candidate-checklist>
    </dxi-item>

    <dxi-item title="Com Log" icon="far fa-comments">
        <dcar-candidate-com-log #candidateComLogComponent></dcar-candidate-com-log>
    </dxi-item>
</dx-tab-panel>