import { NgModule } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRouteGuard } from './shared/common/auth/auth-route-guard';
import { NotificationsComponent } from './shared/layout/notifications/notifications.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmployeeComponent } from './dcar/employee/employee.component';
import { CandidateComponent } from './dcar/candidate/candidate.component';
import { ClientComponent } from './dcar/client/client.component';
import { ContractComponent } from './dcar/contract/contract.component';
import { BillRateComponent } from './dcar/bill-rate/bill-rate.component';
import { PayRateComponent } from './dcar/pay-rate/pay-rate.component';
import { OperationsComponent } from './dcar/operations/operations.component';
import { FinanceComponent } from './dcar/finance/finance.component';
import { QualityComponent } from './dcar/quality/quality.component';
import { AdminComponent } from './dcar/admin/admin.component';
import { ReportsDxComponent } from './reports-dx/reports-dx.component';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'app',
                component: AppComponent,
                canActivate: [AppRouteGuard],
                canActivateChild: [AppRouteGuard],
                children: [
                    {
                        path: '',
                        children: [
                            { path: 'notifications', component: NotificationsComponent },
                            { path: '', redirectTo: '/app/main/dashboard', pathMatch: 'full' },
                        ],
                    },
                    {
                        path: 'dcar/candidate',
                        component: CandidateComponent,
                    },
                    {
                        path: 'dcar/employee',
                        component: EmployeeComponent,
                    },
                    {
                        path: 'dcar/client',
                        component: ClientComponent,
                    },
                    {
                        path: 'dcar/contract',
                        component: ContractComponent,
                    },
                    {
                        path: 'dcar/bill-rate',
                        component: BillRateComponent,
                    },
                    {
                        path: 'dcar/pay-rate',
                        component: PayRateComponent,
                    },
                    {
                        path: 'dcar/operations',
                        component: OperationsComponent,
                    },
                    {
                        path: 'dcar/finance',
                        component: FinanceComponent,
                    },
                    {
                        path: 'dcar/quality',
                        component: QualityComponent,
                    },
                    {
                        path: 'dcar/admin',
                        component: AdminComponent,
                    },
                    {
                        path: 'main',
                        loadChildren: () => import('app/main/main.module').then((m) => m.MainModule), //Lazy load main module
                        data: { preload: true },
                    },
                    {
                        path: 'billing',
                        loadChildren: () => import('./billing/billing.module').then((m) => m.BillingModule),
                        data: { preload: true },
                    },
                    {
                        path: 'companies',
                        loadChildren: () => import('./companies/companies.module').then((m) => m.CompaniesModule),
                        data: { preload: true },
                    },
                    {
                        path: 'candidates',
                        loadChildren: () => import('./candidates/candidates.module').then((m) => m.CandidatesModule),
                        data: { preload: true },
                    },
                    {
                        path: 'programs',
                        loadChildren: () => import('./programs/programs.module').then((m) => m.ProgramsModule),
                        data: { preload: true },
                    },
                    {
                        path: 'technical-specialists',
                        loadChildren: () =>
                        import('./technical-specialists/technical-specialists.module').then((m) => m.TechnicalSpecialistsModule,),
                        data: { preload: true },
                    },
                    {
                        path: 'contracts',
                        loadChildren: () => import('./contracts/contracts.module').then((m) => m.ContractsModule),
                        data: { preload: true },
                    },
                    {
                        path: 'communications',
                        loadChildren: () =>
                            import('./communications/communications.module').then((m) => m.CommunicationsModule),
                        data: { preload: true },
                    },
                    {
                        path: 'documents',
                        loadChildren: () => import('./documents/documents.module').then((m) => m.DocumentsModule),
                        data: { preload: true },
                    },
                    {
                        path: 'reports',
                        loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
                        data: { preload: true },
                    },
                    {
                        path: 'reports-dx',
                        component: ReportsDxComponent,
                    },
                    {
                        path: 'payroll',
                        loadChildren: () => import('./payroll/payroll.module').then((m) => m.PayrollModule),
                        data: { preload: true },
                    },
                    {
                        path: 'lookups',
                        loadChildren: () => import('./lookups/lookups.module').then((m) => m.LookupsModule),
                        data: { preload: true },
                    },
                    { 
                        path: 'timecards', 
                        loadChildren: () => import('./timecards/timecards.module').then(m => m.TimecardsModule),
                        data: { preload: true },
                    },
                    { 
                        path: 'file-manager',
                        loadChildren: () => import('./file-manager/file-manager.module').then(m => m.FileManagerModule),
                        data: { preload: true},
                    },
                    {
                        path: 'exam',
                        loadChildren: () => import('app/exam/exam.module').then((m) => m.ExamModule), //Lazy load main module
                        data: { preload: true },
                    },
                    { 
                        path: 'wc-maintenance',
                        loadChildren: () => import('./wc-maintenance/wc-maintenance.module').then(m => m.WcMaintenanceModule),
                        data: { preload: true},
                    },
                    {
                        path: 'admin',
                        loadChildren: () => import('app/admin/admin.module').then((m) => m.AdminModule), //Lazy load admin module
                        data: { preload: true },
                        canLoad: [AppRouteGuard],
                    },
                    {
                        path: '**',
                        redirectTo: 'notifications',
                    },
                ],
            },
            
        ]),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(private router: Router, private spinnerService: NgxSpinnerService) {
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                spinnerService.show();
            }

            if (event instanceof NavigationEnd) {
                document.querySelector('meta[property=og\\:url').setAttribute('content', window.location.href);
                spinnerService.hide();
            }
        });
    }
}
