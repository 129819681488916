<div class="n-container">
    <div class="grid">
        <div class="col-12">
            <div class="form-group">
                
                <dx-html-editor [disabled]="_candidateService.controlsDisabled" name="resume_text" height="65vh" valueType="html" [(ngModel)]="dataService.selectedCandidate.resume_text">
                    <dxo-toolbar>
                        <dxi-item name="undo"></dxi-item>
                        <dxi-item name="redo"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item
                        name="header"
                        [acceptedValues]="[false, 1, 2, 3, 4, 5]"
                        [options]="{ inputAttr: { 'aria-label': 'Header' } }"
                        ></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="bold"></dxi-item>
                        <dxi-item name="italic"></dxi-item>
                        <dxi-item name="strike"></dxi-item>
                        <dxi-item name="underline"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="alignLeft"></dxi-item>
                        <dxi-item name="alignCenter"></dxi-item>
                        <dxi-item name="alignRight"></dxi-item>
                        <dxi-item name="alignJustify"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                    </dxo-toolbar>
                </dx-html-editor>

                <!-- <p-editor [attr.disabled]="_candidateService.controlsDisabled == true? true: null"  name="resume_text" [style]="{ height: '300px' }"></p-editor> -->
                <!-- <sl-textarea id="resume_text"  value="{{ _candidateService.candidateDetailForm.get('resume_text').value }}"></sl-textarea>
            [(ngModel)]="htmlEditorInput" -->
            </div>
        </div>
    </div>
</div>