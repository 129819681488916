
    <div class="n-container">
        <div class="grid">
            @if (dataService.techPackRequestedByName) { 
            <div class="col-12">
                <span>
                    <h5 class="text-danger">A Tech Pack has been requested by {{dataService.techPackRequestedByName}}</h5>
                </span> 
            </div>
            }
            <div class="col-4">
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="id">ID</label>
                    <dx-text-box id="id" [disabled]="true" [(ngModel)]="dataService.selectedCandidate.id" name="id"></dx-text-box>
                </div>
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="ssn">SSN</label>
                    <dx-text-box id="ssn" [disabled]="_candidateService.controlsDisabled" [(ngModel)]="dataService.selectedCandidate.ssn" name="ssn"></dx-text-box>
                </div>
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="first_name">First Name</label>
                    <dx-text-box id="first_name" [disabled]="_candidateService.controlsDisabled" [(ngModel)]="dataService.selectedCandidate.first_name" name="first_name"></dx-text-box>
                </div>
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="last_name">Last Name</label>
                    <dx-text-box id="last_name" [disabled]="_candidateService.controlsDisabled" [(ngModel)]="dataService.selectedCandidate.last_name" name="last_name"></dx-text-box>
                </div>
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="email">Email</label>
                    <dx-text-box id="email" [disabled]="_candidateService.controlsDisabled" [(ngModel)]="dataService.selectedCandidate.email" name="email"></dx-text-box>
                </div>
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="phone">Phone</label>
                    <dx-text-box id="phone" [disabled]="_candidateService.controlsDisabled" [(ngModel)]="dataService.selectedCandidate.phone" name="phone"></dx-text-box>
                </div>
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="cell">Cell</label>
                    <dx-text-box id="cell" [disabled]="_candidateService.controlsDisabled" [(ngModel)]="dataService.selectedCandidate.cell" name="cell"></dx-text-box>
                </div>
                
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="add1">Address 1</label>
                    <dx-text-box 
                        id="add1" 
                        [disabled]="_candidateService.controlsDisabled" 
                        [(ngModel)]="dataService.selectedCandidate.add_1" name="add1">
                    </dx-text-box>
                </div>
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="add2">Address 2</label>
                    <dx-text-box 
                        id="add2" 
                        [disabled]="_candidateService.controlsDisabled" 
                        [(ngModel)]="dataService.selectedCandidate.add_2" name="add2">
                    </dx-text-box>
                </div>
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="city">City</label>
                    <dx-text-box 
                        id="city" 
                        [disabled]="_candidateService.controlsDisabled" 
                        [(ngModel)]="dataService.selectedCandidate.city" name="city">
                    </dx-text-box>
                </div>
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="state">State</label>
                    <dx-select-box
                        id="state"
                        [dataSource]="dataService.states"
                        valueExpr="code"
                        displayExpr="name"
                        [(ngModel)]="dataService.selectedCandidate.state" name="state"
                        >
                    </dx-select-box>
                </div>
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="zip">Zip</label>
                    <dx-text-box 
                        id="zip" 
                        [disabled]="_candidateService.controlsDisabled" 
                        [(ngModel)]="dataService.selectedCandidate.zip" name="zip">
                    </dx-text-box>
                </div>
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="country">Country</label>
                    <dx-select-box
                        #country
                        id="country"
                        [dataSource]="dataService.countries"
                        valueExpr="code"
                        displayExpr="name"
                        (onItemClick) ="dataService.filterStateList(country.selectedItem.id)"
                        [(ngModel)]="dataService.selectedCandidate.country" name="country"
                        >
                    </dx-select-box>
                </div>
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="pager">Pager</label>
                    <dx-text-box id="pager" [disabled]="_candidateService.controlsDisabled" [(ngModel)]="dataService.selectedCandidate.pager" name="pager"></dx-text-box>
                </div>
                
                
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="tech_pack">Send Tech Pack</label>
                    <br>
                    <dx-check-box #tech_pack id="tech_pack" class="mb-4" [disabled]="_candidateService.controlsDisabled" [(ngModel)]="dataService.selectedCandidate.tech_pack" name="tech_pack"></dx-check-box>
                </div>
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="date_requested">Date Requested</label>
                    <dx-date-box id="date_requested" type="date" [disabled]="true" [(ngModel)]="dataService.selectedCandidate.date_requested" name="date_requested"></dx-date-box>
                </div>
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="tech_pack_link">Tech Pack Link</label>
                    <dx-text-box #tech_pack_link id="tech_pack_link" [disabled]="_candidateService.controlsDisabled" [(ngModel)]="dataService.selectedCandidate.tech_pack_link" name="tech_pack_link"></dx-text-box>
                </div>
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="date_sent">Date Sent</label>
                    <dx-date-box #date_sent id="date_sent" type="date" [disabled]="true" [(ngModel)]="dataService.selectedCandidate.date_sent" name="date_sent"></dx-date-box>
                </div>
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="status">Status</label>
                    <dx-select-box
                        id="status"
                        [dataSource]="dataService.statuses"
                        valueExpr="value"
                        displayExpr="label"
                        [(ngModel)]="dataService.selectedCandidate.status_id" name="status"
                        >
                    </dx-select-box>
                </div>
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="requested_by_name">Requested By</label>
                    <dx-text-box id="requested_by_name" [disabled]="true" [(ngModel)]="dataService.selectedCandidate.requested_by_name" name="requested_by_name"></dx-text-box>
                </div>
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="fax">Fax</label>
                    <dx-text-box id="fax" [disabled]="_candidateService.controlsDisabled" [(ngModel)]="dataService.selectedCandidate.fax" name="fax"></dx-text-box>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label class="emt-label mt-2 mb-2" for="comments">Comments</label>
                    <dx-text-area id="comments" height="90" [disabled]="_candidateService.controlsDisabled" [(ngModel)]="dataService.selectedCandidate.comments" name="comments"></dx-text-area>
                </div>
            </div>
        </div>
    </div>
    