import { Component, Injector } from '@angular/core';
import { TechnicalSpecialistsService } from '@app/technical-specialists/technical-specialists.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DxDataGridModule, DxPivotGridModule } from 'devextreme-angular';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { StimulsoftViewerModule } from 'stimulsoft-viewer-angular';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';




@Component({
  selector: 'app-pivots',
  standalone: true,
  imports: [DxPivotGridModule, DxDataGridModule, StimulsoftViewerModule, FormsModule,HttpClientModule],
  templateUrl: './pivots.component.html',
  styleUrl: './pivots.component.css'
})
export class PivotsComponent extends AppComponentBase {

  dataSource: any[] = [];

  constructor(injector: Injector, private _technicalSpecialistsService: TechnicalSpecialistsService) { 
    super(injector);
    this.getEmployees();
  }

  getEmployees() {
    this._technicalSpecialistsService.getTechnicalSpecialistList().subscribe(data => {
      this.dataSource = data;
      console.log(data);
    });
  }

  onExporting(e: DxDataGridTypes.ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Employees');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
      });
    });
  }

}
