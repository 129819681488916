export class TsDetail {
    person_id: number;
    first_name: string;
    last_name: string;
    address_1: string;
    address_2: string;
    city: string;
    r_state_or_county_id: number;
    state_abbr: string;
    state_name: string;
    postcode: string;
    r_country_id: number;
    country_abbr: string;
    country_name: string;
    country_flag: string;
    phone_primary: string;
    email_primary: string;
    email_secondary: string;
    cell: string;
    fax: string;
    pager: string;
    r_person_status_id: number;
    status_name: string;
    status_badge_color: string;
    status_date: string;
    ceridian_id: string;
    vendor_id: string;
    vendor_payment_method: string;
    main_comments: string
    lat: string;
    lng: string;
    creation_time: string;
    pay_rates_hidden: boolean;
    billable_only: boolean;
    do_not_call: boolean;
    log_text: string;
}