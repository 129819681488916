<div [@routerTransition]>
    <div class="n-container">
        <div class="grid">
            <div class="col-12">
                <dx-button stylingMode="contained" text="Add" [icon]="icon.farPlus" type="success"
                    (onClick)="openAddPopup()" class="mr-2 pull-right">
                </dx-button>

            </div>
            <div class="col-12">
                <dx-tab-panel width="100%" [animationEnabled]="true">
                    <dxi-item title="Candidates (All)" icon="far fa-user">
                        <dcar-candidate-list #candidateListComponent></dcar-candidate-list>
                    </dxi-item>
                    <dxi-item title="Candidates (Tech Pack Requested)" icon="far fa-list-alt">
                        <dcar-candidate-tech-pack #candidateTechPackComponent></dcar-candidate-tech-pack>
                    </dxi-item>
                </dx-tab-panel>
            </div>
        </div>
    </div>

    <dx-popup id="update-popup" [(visible)]="_candidateService.isUpdatePopupVisible" contentTemplate="template"
        hideOnOutsideClick="false" height="90vh" position="center">

        <div *dxTemplate="let data of 'template'">
            <div class="grid">
                <div class="col-6">
                    <header class="drawer_title">
                        {{dataService.selectedCandidate.last_name}}, {{dataService.selectedCandidate.first_name}}
                    </header>
                </div>
                <div class="col-6" style="align-content:flex-end;">


                    <dx-button stylingMode="contained" text="Close" type="danger" (onClick)="closeDrawer()"
                        class="mr-2 pull-right">
                    </dx-button>


                    <dx-button [disabled]="idEditModeDisabled"
                        stylingMode="contained" text="Save" type="success" (onClick)="updateCandidate()"
                        class="mr-2 pull-right">
                    </dx-button>


                    <dx-drop-down-button class="mr-2 pull-right" text="Action" [dropDownOptions]="{ width: 230 }" icon="far fa-list-alt"
                        [items]="dataService.candidateOptionsMenuChoices" displayExpr="name" keyExpr="id"
                        (onItemClick)="onCandidateOptionsClick($event)"></dx-drop-down-button>


                    <dx-button stylingMode="contained" [text]="editButtonText" type="normal" (onClick)="edit()"
                        class="mr-2 pull-right">
                    </dx-button>

                </div>
            </div>

            <dcar-candidate-detail-tabs #candidateDetailTabsComponent></dcar-candidate-detail-tabs>

        </div>

    </dx-popup>

    <dx-popup id="add-popup" [(visible)]="_candidateService.isAddPopupVisible" contentTemplate="createTemplate"
        hideOnOutsideClick="false" height="70vh" width="50vw" title="Add New Candidate" position="center">

        <div *dxTemplate="let data of 'createTemplate'">
            <dcar-candidate-create #candidateCreateComponent></dcar-candidate-create>
        </div>
    </dx-popup>

    <dx-popup id="request-tech-pack-popup" width="400" height="240" shading="false"
        [(visible)]="_candidateService.isRequestTechPackPopupVisible" contentTemplate="requestTechPackTemplate"
        hideOnOutsideClick="false" title="Request Tech Pack" position="center">

        <div *dxTemplate="let data of 'requestTechPackTemplate'">
            <div class="grid">

                <div class="col-12">
                    <div class="emt-label">Select Delivery Type</div>
                </div>
                <div class="col-12">
                    <div class="emt-field">
                        <dx-select-box id="delivery_type" [(ngModel)]="selectedDeliveryType"
                            [items]="staticDataService.deliveryTypes" displayExpr="label" valueExpr="value">
                        </dx-select-box>
                    </div>
                </div>
                <div class="col-12 text-center">
                    <dx-button [disabled]="!selectedDeliveryType" text="Submit" type="success"
                        (onClick)="requestTechPack()">
                    </dx-button>
                </div>
            </div>
        </div>
    </dx-popup>



</div>