<div class="modal-header">
    <h4 class="modal-title pull-left">{{ emtDialogService.options.title }}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ul *ngIf="emtDialogService.options.list.length">
      <li *ngFor="let item of emtDialogService.options.list">{{ item }}</li>
    </ul>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{ emtDialogService.options.closeBtnName }}</button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{ emtDialogService.options.closeBtnName }}</button>
  </div>