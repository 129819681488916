import {Component, forwardRef, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {AppComponentBase} from "@shared/common/app-component-base";
import {appModuleAnimation} from "@shared/animations/routerTransition";
import {DxReportViewerModule} from "@node_modules/devexpress-reporting-angular";
import { UtilsModule } from '@shared/utils/utils.module';
import { ViewerComponent } from './viewer/viewer.component';
import { HeaderModule } from '@app/header/header.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CommonModule } from '@angular/common';


@Component({
    selector: 'app-reports-dx',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    templateUrl: './reports-dx.component.html',
    styleUrls: ['./reports-dx.component.css',
        '../../../node_modules/devextreme/dist/css/dx.common.css',
        '../../../node_modules/devextreme/dist/css/dx.light.css',
        '../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css',
        '../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css',
        '../../../node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css'],
    imports: [CommonModule, DxReportViewerModule, UtilsModule, ViewerComponent, forwardRef(() => HeaderModule), DashboardComponent],
    animations: [appModuleAnimation()]
})
export class ReportsDxComponent extends AppComponentBase implements OnInit {

    title = 'R001';
    reportUrl = 'R001';
    hostUrl = 'https://localhost:44304/';
    invokeAction = 'DXXRDV';

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit(): void {
    }
}