import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SignalService } from '@app/shared/_services/signal.service';
import { TechnicalSpecialistsService } from '@app/technical-specialists/technical-specialists.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor(
    private router: Router,
    private technicalSpecialistsService: TechnicalSpecialistsService,
    private signalService: SignalService
  ) {
    router.events.subscribe((val) => {
      this.display_sub_page = '';
      const position = router.url.split('/').length - 1;
      this.page_header = router.url.split('/')[position].replace('-', ' ').toUpperCase();
      //this.page_header = router.url.split('/')[2].replace('-',' ').toUpperCase();
      this.signalService.page_title.set(this.page_header);

      switch (this.page_header) {
        case 'DASHBOARD':
          this.page_icon = 'fa-solid fa-chart-line'
          this.signalService.page_icon.set('fa-solid fa-chart-line')
          this.header_background = 'n-bg-white'
          break;
        case 'CANDIDATES':
          this.page_icon = 'fa-solid fa-poll-people'
          this.signalService.page_icon.set('fa-solid fa-poll-people')
          this.header_background = 'n-bg-candidates'
          break;
        case 'TECHNICAL SPECIALISTS':
          this.page_icon = 'fa-solid fa-id-badge'
          this.signalService.page_icon.set('fa-solid fa-id-badge')
          this.header_background = 'n-bg-technical-specialists'
          break;
        case 'COMPANIES':
          this.page_icon = 'fa-solid fa-building'
          this.signalService.page_icon.set('fa-solid fa-building')
          this.header_background = 'n-bg-companies'
          break;
        case 'CONTRACTS':
          this.page_icon = 'fa-solid fa-file-contract'
          this.signalService.page_icon.set('fa-solid fa-file-contract')
          this.header_background = 'n-bg-contracts'
          break;
        case 'PAYROLL':
          this.page_icon = 'fa-solid fa-money-check-edit-alt'
          this.signalService.page_icon.set('fa-solid fa-money-check-edit-alt')
          this.header_background = 'n-bg-payroll'
          break;
        case 'BILLING':
          this.page_icon = 'fa-solid fa-file-invoice'
          this.signalService.page_icon.set('fa-solid fa-file-invoice')
          this.header_background = 'n-bg-billing'
          break;
        case 'TIMECARDS':
          this.page_icon = 'fa-solid fa-money-check-dollar-pen'
          this.signalService.page_icon.set('fa-solid fa-money-check-dollar-pen')
          this.header_background = 'n-bg-white'
          break;
        case 'COMMUNICATIONS':
          this.page_icon = 'fa-solid fa-tower-broadcast'
          this.signalService.page_icon.set('fa-solid fa-tower-broadcast')
          this.header_background = 'n-bg-communications'
          break;
        case 'REPORTS':
          this.page_icon = 'fa-solid fa-chart-bar'
          this.signalService.page_icon.set('fa-solid fa-chart-bar')
          this.header_background = 'n-bg-reports'
          break;
        case 'DOCUMENTS':
          this.page_icon = 'fa-solid fa-file-alt'
          this.signalService.page_icon.set('fa-solid fa-file-alt')
          this.header_background = 'n-bg-documents'
          break;
        case 'LOOKUPS':
          this.page_icon = 'fa-solid fa-layer-group'
          this.signalService.page_icon.set('fa-solid fa-layer-group')
          this.header_background = 'n-bg-lookups'
          break;
        case 'ADMIN':
          this.page_icon = 'fa-solid fa-gears'
          this.signalService.page_icon.set('fa-solid fa-gears')
          this.header_background = 'n-bg-admin'
          break;
        default:
          this.page_icon = 'fa-solid fa-rocket'
          this.signalService.page_icon.set('fa-solid fa-rocket')
          this.header_background = 'n-bg-white'
          break;
      }

    });
  }


  page_header: string = 'DASHBOARD';
  header_background: string = 'n-bg-white';
  page_icon: string = 'fa-solid fa-chart-line';
  sub_page_header: string = '';
  sub_sub_page_header: string = '';
  display_sub_page: string = '';
  display_sub_sub_page: string = '';

  displaySubPage(page: string, value?: string) {
    this.display_sub_page = page;
    this.sub_page_header = page.replace('-', ' ').toUpperCase();
    this.technicalSpecialistsService.selectedPersonIdTs = parseInt(value);
  }

  displaySubSubPage(page: string, value?: string) {
    this.display_sub_sub_page = page;
    this.sub_sub_page_header = page.replace('-', ' ').toUpperCase();
    //this.technicalSpecialistsService.selectedPersonIdTs = parseInt(value);
  }
}
