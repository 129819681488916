export class CandidateChecklist {
    id: number;
    candidate_id: number;
    i9_received: boolean;
    i9_received_date: string;
    support_docs_notarized: string;
    support_docs_notarized_date: string;
    list_type: string;
    w4_received: boolean;
    w4_received_date: string;
    signed_application_received: boolean;
    signed_application_received_date: string;
    resume_received: boolean;
    resume_received_date: string;
    professional_references_received: boolean;
    professional_references_received_date: string;
    previous_employment_history_received: boolean;
    previous_employment_history_received_date: string;
    code_of_ethics_received: boolean;
    code_of_ethics_received_date: string;
    nda_received: boolean;
    nda_received_date: string;
    registration_agreement_received: boolean;
    registration_agreement_received_date: string;
    ethical_practices_received: boolean;
    ethical_practices_received_date: string;
    higher_education_verified: string;
    higher_education_verified_date: string;
    how_higher_education_verified: string;
    how_higher_education_verified_date: string;
    university_representitive_name: string;
    certifications_verified: string;
    certifications_verified_date: string;
    direct_deposit_form_received: boolean;
    direct_deposit_form_received_date: string;
    delivery_type : string;
    assigned_ts_number: number;
    date_of_birth: string;
    social_security_number: string;
    citizen_status: string;
    date_techpack_sent: string;
    tech_pack: string;
    first_name: string;
    last_name: string;
    ts_number_requested: boolean;
    ts_number_requested_date: string;
}