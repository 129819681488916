import { CommonModule } from '@angular/common';
import { Component, Injector, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CandidateService } from './candidate.service';
import { DxButtonModule, DxCheckBoxModule, DxDateBoxModule, DxSelectBoxModule, DxTextBoxModule } from 'devextreme-angular';
import { SharedService } from '@app/shared/_services/shared.service';
import { CandidateChecklist } from './_models/candidate-checklist.interface';
import { StaticLookup } from '@app/shared/_models/static-lookup.model';

@Component({
  selector: 'dcar-candidate-checklist',
  standalone: true,
  imports: [
            CommonModule,
            FormsModule, 
            ReactiveFormsModule, 
            DxTextBoxModule, 
            DxDateBoxModule,
            DxSelectBoxModule,
            DxCheckBoxModule,
            DxButtonModule
          ],
  templateUrl: './candidate-checklist.component.html',
  styleUrl: './candidate-checklist.component.css'
})
export class CandidateChecklistComponent extends AppComponentBase {
  @ViewChild('candidateChecklist') candidateChecklist
  
  //controlsDisabled = true;
  deliveryTypes: StaticLookup[] = []; 
  educationVerifyTypes: StaticLookup[] = []; 
  
  constructor(
    injector: Injector,
    public _candidateService: CandidateService,
    private _sharedService: SharedService,
    
  ) {
    super(injector);
    this.dataService.selectedChecklist = new CandidateChecklist();
    this.dataService.originalChecklist = new CandidateChecklist();
  }

  ngOnInit() {
    this.deliveryTypes = this.staticDataService.getDeliveryTypes();
    this.educationVerifyTypes = this.staticDataService.educationVerifyTypes();
  }

  submitForm() {
    this.candidateChecklist.nativeElement.submit();
  }

  changeVal(e) {
    console.log('e',e);
  }

 

  setReceivedDate(e,doc) {

    let value: any;
    value = e.value? new Date(): null;
      
      switch (doc) {
        case 'ts_number_requested':
          this.dataService.selectedChecklist.ts_number_requested_date = this._sharedService.formatDate(value);
          break;
        case 'signed_application_received':
          this.dataService.selectedChecklist.signed_application_received_date = this._sharedService.formatDate(value);
          break;
        case 'code_of_ethics_received':
          this.dataService.selectedChecklist.code_of_ethics_received_date = this._sharedService.formatDate(value);
          break;
        case 'nda_received':
          this.dataService.selectedChecklist.nda_received_date = this._sharedService.formatDate(value);
          break;
        case 'registration_agreement_received':
          this.dataService.selectedChecklist.registration_agreement_received_date = this._sharedService.formatDate(value);
          break;
        case 'ethical_practices_received':
          this.dataService.selectedChecklist.ethical_practices_received_date = this._sharedService.formatDate(value);
          break;
        case 'i9_received':
          this.dataService.selectedChecklist.i9_received_date = this._sharedService.formatDate(value);
          break;
        case 'w4_received':
          this.dataService.selectedChecklist.w4_received_date = this._sharedService.formatDate(value);
          break;
        case 'resume_received':
          this.dataService.selectedChecklist.resume_received_date = this._sharedService.formatDate(value);
          break;
        case 'professional_references_received':
          this.dataService.selectedChecklist.professional_references_received_date = this._sharedService.formatDate(value);
          break;
        case 'previous_employment_history_received':
          this.dataService.selectedChecklist.previous_employment_history_received_date = this._sharedService.formatDate(value);
          break;
        case 'higher_education_verified':
          this.dataService.selectedChecklist.higher_education_verified_date = this._sharedService.formatDate(value);
          
          if(!value){
            this.dataService.selectedChecklist.how_higher_education_verified = '';
            this.dataService.selectedChecklist.university_representitive_name = '';
          }

          break;
        case 'direct_deposit_form_received':
          this.dataService.selectedChecklist.direct_deposit_form_received_date = this._sharedService.formatDate(value);
          break;
        case 'certifications_verified':
          this.dataService.selectedChecklist.certifications_verified_date = this._sharedService.formatDate(value);
          break;
        case 'support_docs_notarized':
          this.dataService.selectedChecklist.support_docs_notarized_date = this._sharedService.formatDate(value);
          break;
           
        default:
          break;
    }

    
  }

  private formatDate(value: Date): string {
    if (!value) {
      return null;
    }
    const date = new Date(value);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  requestTsNumber(e) {

  }

}
