export class CandidateDetail {
    add_1: string;
    add_2: string;
    cell: string;
    city: string;
    comments: string;
    country_id: number;
    country: string;
    date_requested: string;
    date_sent: string;
    email: string;
    fax: string;
    first_name: string;
    id: number;
    last_name: string;
    pager: string;
    phone: string;
    requested_by_id: string;
    requested_by_name: string;
    resume_text: string;
    ssn: string;
    state: string;
    status_id: number;
    tech_pack_link: string;
    tech_pack: number;
    zip: string;
}