import { Component, Input } from '@angular/core';

@Component({
  selector: 'emt-contact-display',
  templateUrl: './emt-contact-display.component.html',
  styleUrl: './emt-contact-display.component.css'
})
export class EmtContactDisplayComponent {
  @Input() contact: any;
}
