import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, effect, ElementRef, Injector, ViewChild } from '@angular/core';
import { AppCommonModule } from '@app/shared/common/app-common.module';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CandidateListComponent } from "./candidate-list.component";
import { CandidateTechPackComponent } from "./candidate-tech-pack.component";
import { CandidateService } from './candidate.service';
import { CandidateChecklistComponent } from './candidate-checklist.component';
//import { CandidateDetailComponent } from './candidate-detail.component';
import { CandidateDetailTabsComponent } from './candidate-detail-tabs.component';
import { DxButtonModule, DxDrawerModule, DxDropDownButtonModule, DxPopupComponent, DxPopupModule, DxSelectBoxModule, DxTabPanelModule } from 'devextreme-angular';
import { CandidateDetail } from './_models/candidate-detail.interface';
import { DxiItemModule } from 'devextreme-angular/ui/nested';
import { CandidateCreateComponent } from './candidate-create.component';
import { StaticDataService } from '@app/shared/_services/static-data.service';
import { DxDropDownButtonTypes } from 'devextreme-angular/ui/drop-down-button';
//import { SlDrawer } from '@shoelace-style/shoelace';
import notify from 'devextreme/ui/notify';
import { DxDropDownButtonItemObject } from '@app/shared/_types/dx-drop-down-button-item-object.type';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'dcar-candidate',
  standalone: true,
  imports: [
      CommonModule, 
      AppCommonModule, 
      FormsModule,
      CandidateListComponent, 
      CandidateTechPackComponent, 
      CandidateChecklistComponent, 
      CandidateCreateComponent, 
      CandidateDetailTabsComponent, 
      DxButtonModule, 
      DxDrawerModule, 
      DxTabPanelModule, 
      DxiItemModule, 
      DxPopupModule,
      DxDropDownButtonModule,
      DxSelectBoxModule,
    ],

  templateUrl: './candidate.component.html',
  styleUrl: './candidate.component.css',
  animations: [appModuleAnimation()],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CandidateComponent extends AppComponentBase implements AfterViewInit {
  @ViewChild('detailTab') detailTab!: ElementRef;
  @ViewChild('candidateDetailPopup') candidateDetailPopup: DxPopupComponent;
  @ViewChild('candidateDetailTabsComponent') candidateDetailTabsComponent:CandidateDetailTabsComponent;
//  @ViewChild('candidateDetailComponent') candidateDetailComponent:CandidateDetailComponent;
  idEditModeDisabled: boolean = true;
  editButtonText = 'Edit';
  //candidateOptionsMenuChoices: DxDropDownButtonItemObject[];
  selectedDeliveryType: number;
  //_candidateService.isUpdatePopupVisible: boolean;
  //_candidateService.isAddPopupVisible: boolean;

  constructor(
    injector: Injector,
    public _candidateService: CandidateService,
    private _staticDataService: StaticDataService,
  ) {
    super(injector);
    this._candidateService.isUpdatePopupVisible = false;
    //this.candidateOptionsMenuChoices = this._staticDataService.candidateOptionsMenu;

    

    effect(() => {
      const open = this.signalService.openCandidateDetailPopup();
      if (open) {
        this._candidateService.isUpdatePopupVisible = true;
      }
    });
  }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    
    //this.candidateDetailPopup.onDisposing.subscribe(() => {
    //  this.signalService.openCandidateDetailPopup.set(false);
    //});
    //this.candidateDetailPopup.nativeElement.addEventListener('sl-after-hide', () => {
    //  this.signalService.openCandidateDetailPopup.set(false);
    //});
  }



  focusDetailTab(): void {
    if (this.detailTab) {
      this.detailTab.nativeElement.focus();
    }
  }

  closeDrawer() {
    this._candidateService.isUpdatePopupVisible = false;
    this.signalService.openCandidateDetailPopup.set(false);
    this._candidateService.controlsDisabled = true;
    this.dataService.originalCandidate = new CandidateDetail();
    this.editButtonText = 'Edit';
    this.idEditModeDisabled = true;
  }

  openAddPopup() {
    this.dataService.selectedCandidate = new CandidateDetail();
    this.dataService.originalCandidate = new CandidateDetail();
    this.dataService.techPackRequestedByName = '';
    this.dataService.selectedCandidate.status_id = 1;
    this._candidateService.isAddPopupVisible = true;
  }

  edit() {
    this.candidateDetailTabsComponent.edit();
    this.editButtonText = this._candidateService.controlsDisabled ? 'Edit' : 'Cancel';
    this.idEditModeDisabled = this._candidateService.controlsDisabled? true : false;
  }

  updateCandidate() {
    this.candidateDetailTabsComponent.updateCandidate();
  }

  requestTsNumber(e) {
    this.candidateDetailTabsComponent.requestTsNumber(e);
  }

  onCandidateOptionsClick(e: DxDropDownButtonTypes.ItemClickEvent): void {
    console.log(e);
    switch (e.itemData.value) {
      case 1: //Request tech Pack
          this._candidateService.isRequestTechPackPopupVisible = true;
        break;
      case 2: //Convert to TS
      this._candidateService.isConvertToTsPopupVisible = true;
        break;
    
      default:
        break;
    }    //this._candidateService.isRequestTechPackPopupVisible = true;
  }

  requestTechPack() {
    this._candidateService.isRequestTechPackPopupVisible = false;
    notify('Tech Pack Requested', 'success', 3000);
    const delivery_type = this._staticDataService.deliveryTypes.find(d => d.value == this.selectedDeliveryType)?.label;
    this._candidateService.requestTechPack(this.dataService.selectedCandidateId, this.appSession.user.dcar_id, delivery_type).subscribe(() => {
      this._candidateService.getCandidateList().subscribe((result) => {
        this.dataService.ds_candidateList = result;
      });
      this.dataService.getCandidateDetail({id: this.dataService.selectedCandidateId });
    });
  }
}
