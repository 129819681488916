import { Component, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DxButtonModule, DxCheckBoxModule, DxDateBoxModule, DxHtmlEditorModule, DxPopupModule, DxSelectBoxComponent, DxSelectBoxModule, DxTextAreaModule, DxTextBoxModule } from 'devextreme-angular';
import { CandidateService } from './candidate.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { EditorModule } from 'primeng/editor';
import { SharedService } from '@app/shared/_services/shared.service';
import { CandidateDetail } from './_models/candidate-detail.interface';

@Component({
  selector: 'dcar-candidate-create',
  standalone: true,
  imports: [
            CommonModule, 
            FormsModule, 
            ReactiveFormsModule, 
            EditorModule, 
            DxTextBoxModule, 
            DxCheckBoxModule,
            DxTextAreaModule,
            DxHtmlEditorModule,
            DxDateBoxModule,
            DxSelectBoxModule,
            DxPopupModule,
            DxButtonModule,
  ],
  templateUrl: './candidate-create.component.html',
  styleUrl: './candidate-create.component.css'
})
export class CandidateCreateComponent extends AppComponentBase {
  @Output() closeAddPopup = new EventEmitter<boolean>();


  constructor(
    injector: Injector,
    public _candidateService: CandidateService,
    private _sharedService: SharedService,
  ) {
    super(injector);
    this.dataService.getCountries();
    this.dataService.getStatuses();
  }

  setDateRequested(e) {
    let value: any;
    let requested_by_id: any;
    let requested_by_name: any;

    value = e.value? new Date(): null;
    requested_by_id = e.value? this.appSession.dcar_id.toString(): null;
    requested_by_name = e.value? `${this.appSession.user.name} ${this.appSession.user.surname}`: null;

    console.log(e);
    this.dataService.selectedCandidate.requested_by_id = requested_by_id;
    this.dataService.selectedCandidate.requested_by_name = requested_by_name;
    this.dataService.selectedCandidate.date_requested = this._sharedService.formatDate(value);
  }

  createCandidate() {
    this._candidateService.createCandidate(this.dataService.selectedCandidate).subscribe((result) => {
      
      if (result.status ='success') {
        this.notify.success(this.l('SavedSuccessfully'));
        this.dataService.selectedCandidate = new CandidateDetail();
        //this.closeParentPopup();
        this._candidateService.closeAddPopup(result);
        this.dataService.getCandidateList();
      } else {
        this.message.error('Error', 'Error saving candidate');
      }
    });
  }

  //closeParentPopup() {
  //  this.closeAddPopup.emit(true);
  //}

  
}
