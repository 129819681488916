import { Injectable } from '@angular/core';
import { HttpBaseService } from '@app/shared/_services/http-base.service';
import { Observable } from 'rxjs';
import { Lookup } from './_models/lookup.model';
import { BaseLookupModel } from './_models/base-lookup.model';
import { WorkType } from './_models/work-type.model';
import { ClientId, ClientWorkType } from './_models/client-work-type.model';
import { NamePrefix } from './_models/name-prefix.model';
import { NameSuffix } from './_models/name-suffix.model';
import { Country } from './_models/country.model';
import { PersonStatus } from './_models/person-status.model';
import { EmailType } from './_models/email.model';
import { AddressType } from './_models/address-type.model';
import { State } from './_models/state-list.model';
import { ReferenceTable } from './_models/reference-table.model';

@Injectable({
    providedIn: 'root',
})
export class LookupsService {
    strRecordDesc: string = '';
    strRecordDescPlural: string = '';
    recordDesc: string;
    recordDescPlural: string;
    pathGet: string;
    pathCreate: string;
    pathUpdate: string;
    pathDelete: string;
    refTableCom: string;
    toasterTimeout: number = 5000;

    constructor(private _httpBaseService: HttpBaseService) { }

    //setRecordDesc() {
    //    this.recordDesc = abp.utils.replaceAll(this.strRecordDesc, ' ', '')
    //    this.recordDescPlural = abp.utils.replaceAll(this.strRecordDescPlural, ' ', '')
    //    this.pathGet = `lookups/${this.recordDesc}/Get${this.recordDescPlural}/`;
    //    this.pathCreate = `lookups/${this.recordDesc}/Create${this.recordDesc}/`;
    //    this.pathUpdate = `lookups/${this.recordDesc}/Update${this.recordDesc}/`;
    //    this.pathDelete = `lookups/${this.recordDesc}/Delete${this.recordDesc}/`;
    //}

    getOne(baseLookupModel: BaseLookupModel): Observable<BaseLookupModel> {
        return this._httpBaseService.get(`lookups/${this.recordDesc}/get-one/`);
    }

    getAll(recordDescAPI: string): Observable<BaseLookupModel[]> {
        ////this.setRecordDesc();
       //return this._httpBaseService.get(this.pathGet + abp.session.userId + '/false',);
       return this._httpBaseService.get(`lookups/get-all/${recordDescAPI}`);
    }

    createOne(baseLookupModel: BaseLookupModel): Observable<any> {
        ////this.setRecordDesc();
       return this._httpBaseService.post(`lookups/${this.recordDesc}/create-one/`, baseLookupModel);
       //return this._httpBaseService.post(this.pathCreate, baseLookupModel);
    }
    
    createMany(baseLookupModel: BaseLookupModel[]){
        return this._httpBaseService.post(`lookups/${this.recordDesc}/create-many/`, baseLookupModel);
    }

    updateOne(baseLookupModel: BaseLookupModel): Observable<any> {
        ////this.setRecordDesc();
       return this._httpBaseService.post(`lookups/${this.recordDesc}/update-one/`, baseLookupModel);
    }

    updateMany(baseLookupModel: BaseLookupModel[]){
        return this._httpBaseService.post(`lookups/${this.recordDesc}/update-many/`, baseLookupModel);
    }

    deleteOne(baseLookupModel): Observable<any> {
        ////this.setRecordDesc();
       return this._httpBaseService.post(`lookups/${this.recordDesc}/delete-one/`, baseLookupModel);
    }
    
    deleteMany(baseLookupModel: BaseLookupModel[]){
        return this._httpBaseService.post(`lookups/${this.recordDesc}/delete-many/`, baseLookupModel);
    }

    

    getAllCountries(): Observable<any[]> {
        return this._httpBaseService.get('lookups/get-all-countries');
    }

    jobTs(person_id_ts: number): Observable<Lookup[]> {
        return this._httpBaseService.get(`lookups/job/${person_id_ts}`);
    }

    jobDashTsJob(person_id_ts: number, job: string): Observable<Lookup[]> {
        return this._httpBaseService.get(`lookups/job-dash/${person_id_ts}/${job}`);
    }

    states(r_country_id: number): Observable<Lookup[]> {
        return this._httpBaseService.get(`lookups/get-states/${r_country_id}`);
    }

    //countries(): Observable<Lookup[]> {
    //    return this._httpBaseService.get('lookups/get-all-countries');
    //}

    getTsStatuses(): Observable<Lookup[]> {
        return this._httpBaseService.get('lookups/get-ts-statuses');
    }
    
    getWorkTypes(): Observable<WorkType[]> {
        return this._httpBaseService.get('/WorkType/GetWorkTypes/' + abp.session.userId + '/false');
    }

    addWorkType(workType: WorkType): Observable<WorkType> {
        return this._httpBaseService.post('/WorkType/CreateWorkType', workType);
    }

    editWorkType(workType: WorkType): Observable<WorkType> {
        return this._httpBaseService.post('/WorkType/UpdateWorkType', workType);
    }

    deleteWorkTypes(workType) {
        return this._httpBaseService.post('/WorkType/DeleteWorkType', workType);
    }

    getClientIds(): Observable<ClientId[]> {
        return this._httpBaseService.get('/Client/GetClientList/' + abp.session.userId + '/false');
    }

    getClientWorkTypes(): Observable<ClientWorkType[]> {
        return this._httpBaseService.get('/ClientWorkType/GetClientWorkTypes/' + abp.session.userId + '/false');
    }

    addClientWorkType(clientWorkType: ClientWorkType): Observable<ClientWorkType> {
        return this._httpBaseService.post('/ClientWorkType/CreateClientWorkType', clientWorkType);
    }

    editClientWorkType(clientWorkType: ClientWorkType): Observable<ClientWorkType> {
        return this._httpBaseService.post('/ClientWorkType/UpdateClientWorkType', clientWorkType);
    }

    deleteClientWorkType(deletedClientWorkType: any) {
        return this._httpBaseService.post('/ClientWorkType/DeleteClientWorkType', deletedClientWorkType);
    }

    getRecords(): Observable<ReferenceTable[]> {
        //this.setRecordDesc();
        return this._httpBaseService.get(this.refTableCom);
    }

    addRecord(baseDdModel: any) {
        //this.setRecordDesc();
        return this._httpBaseService.post(this.refTableCom, baseDdModel);
    }

    editRecord(baseDdModel: any): Observable<any> {
        //this.setRecordDesc();
        return this._httpBaseService.patch(this.refTableCom + '/' + baseDdModel.Id, baseDdModel);
    }

    deleteRecord(deleteRecord): Observable<ReferenceTable[]> {
        //this.setRecordDesc();
        return this._httpBaseService.delete(this.refTableCom + '/' + deleteRecord);
    }

    getNamePrefixList(): Observable<NamePrefix[]> {
        return this._httpBaseService.get('r-name-prefix');
    }

    getNameSuffixList(): Observable<NameSuffix[]> {
        return this._httpBaseService.get('r-name-suffix');
    }

    getCountryList(): Observable<Country[]> {
        return this._httpBaseService.get('r-country');
    }

    getStateListByCountryId(country): Observable<State[]> {
        console.log('fired');
        return this._httpBaseService.get('r-state-or-county/country/' + country);
    }

    getPersonStatusList(): Observable<PersonStatus[]> {
        return this._httpBaseService.get('r-person-status');
    }

    getEmailTypeList(): Observable<EmailType[]> {
        return this._httpBaseService.get('r-email-type');
    }

    getAddressTypeList(): Observable<AddressType[]> {
        return this._httpBaseService.get('r-address-type');
    }

    
}
