import { TechnicalSpecialistDashboard } from './_models/technical-specialist-dashboard.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { TechnicalSpecialistList } from './_models/technical-specialist-list.model';
import { HttpBaseService } from '@app/shared/_services/http-base.service';
import { TechnicalSpecialistTopTen } from './_models/technical-specialist-top-ten.model';
import { TechnicalSpecialistMargin } from './_models/technical-specialist-margin.model';
import { TechnicalSpecialistWorldActivity } from './_models/technical-specialist-world-activity.model';
import { TechnicalSpecialistPayHistory } from './_models/technical-specialist-pay-history.model';
import { TechnicalSpecialistView } from './_models/technical-specialist-view.model';
import { TechnicalSpecialistEyeExam } from './_models/technical-specialist-eye-exam.model';
import { TechnicalSpecialistDrugTest } from './_models/technical-specialist-drug-test.model';
import { TechnicalSpecialistStampControl } from './_models/technical-specialist-stamp-control.model';
import { TechnicalSpecialistComplianceDoc } from './_models/technical-specialist-compliance-doc.model';
import { TechnicalSpecialistTraining } from './_models/technical-specialist-training.model';
import { TechnicalSpecialistDetail } from './_models/technical-specialist-detail.model';
import { TsDetail } from './_models/ts-detail.model';
import { LookupsService } from '@app/lookups/lookups.service';
import { Lookup } from '@app/lookups/_models/lookup.model';
import { AppMessagingService } from '@app/shared/_services/app-messaging.service';
import { TechnicalSpecialistListWithReceipts } from './_models/technical-specialist-list-with-receipts.model';
import moment from 'moment';

@Injectable({
    providedIn: 'root'
})

export class TechnicalSpecialistsService {

    selectedPage: string = 'dashboard';

    ds_tsDetail: TsDetail = new TsDetail();
    ds_tsDetailClone: TsDetail = new TsDetail();
    ds_states: Lookup[] = [];
    ds_countries: Lookup[] = [];
    ds_tsStatuses: Lookup[] = [];
    
    ds_billRates: any[] = [];

    technicalSpecialistList: Observable<TechnicalSpecialistList[]>;
    technicalSpecialistDashboard: Observable<TechnicalSpecialistDashboard>;
    technicalSpecialistTopTen: Observable<TechnicalSpecialistTopTen[]>;
    technicalSpecialistMargin: Observable<TechnicalSpecialistMargin[]>;
    technicalSpecialistPayHistory: Observable<TechnicalSpecialistPayHistory[]>;
    technicalSpecialistLastTenHired: Observable<TechnicalSpecialistView[]>;

    technicalSpecialistEyeExamList: Observable<TechnicalSpecialistEyeExam[]>;
    technicalSpecialistDrugTestList: Observable<TechnicalSpecialistDrugTest[]>;
    technicalSpecialistStampControlList: Observable<TechnicalSpecialistStampControl[]>;
    technicalSpecialistComplianceDocList: Observable<TechnicalSpecialistComplianceDoc[]>;
    technicalSpecialistTrainingList: Observable<TechnicalSpecialistTraining[]>;
    //tsWorldActivity: Observable<TechnicalSpecialistWorldActivity[]>;

    //query: string;
    //position: string;
    //locations: Array<any>;
    //address = '';
    //tempLat: string;
    //tempLng: string;
    selectedPersonIdTs: number;
    //selectedTechnicalSpecialistName: string;
    //currentPage: string;
    tsEdit: boolean = false;
    fieldLocked: boolean = true;

    constructor(
        private _httpBaseService: HttpBaseService,
        private _lookupService: LookupsService,
        private _messagingService: AppMessagingService,
    ) {
    }

    sendMessage(msg): void {
        // send message to subscribers via observable subject
        this._messagingService.sendMessage(msg);
    }

    clearMessages(): void {
        // clear messages
        this._messagingService.clearMessages();
    }

    getTechnicalSpecialistList(): Observable<TechnicalSpecialistList[]> {
        return this._httpBaseService.get('people/technical-specialists/get-all');
    }
    
    getTechnicalSpecialistDashboard(): Observable<TechnicalSpecialistDashboard> {
        return this._httpBaseService.get('people/technical-specialists/dashboard');
    }

    getTechnicalSpecialistTopTen(): Observable<TechnicalSpecialistTopTen[]> {
        return this._httpBaseService.get('people/technical-specialists/top-ten');
    }

    getTechnicalSpecialistMargin(): Observable<TechnicalSpecialistMargin[]> {
        return this._httpBaseService.get('people/technical-specialists/margin');
    }

    getTechnicalSpecialistPayHistory(): Observable<TechnicalSpecialistPayHistory[]> {
        return this._httpBaseService.get('people/technical-specialists/pay-history');
    }

    getTechnicalSpecialistLastTenHired(): Observable<TechnicalSpecialistView[]> {
        return this._httpBaseService.get('people/technical-specialists/last-ten-hired');
    }

    getTechnicalSpecialistEyeExam(): Observable<TechnicalSpecialistEyeExam[]> {
        return this._httpBaseService.get('people/technical-specialists/eye-exam-list');
    }

    getTechnicalSpecialistDrugTest(): Observable<TechnicalSpecialistDrugTest[]> {
        return this._httpBaseService.get('people/technical-specialists/drug-test-list');
    }

    getTechnicalSpecialistStampControl(): Observable<TechnicalSpecialistStampControl[]> {
        return this._httpBaseService.get('people/technical-specialists/stamp-control-list');
    }

    getTechnicalSpecialistComplianceDoc(): Observable<TechnicalSpecialistComplianceDoc[]> {
        return this._httpBaseService.get('people/technical-specialists/compliance-doc-list');
    }

    getTechnicalSpecialistTraining(): Observable<TechnicalSpecialistTraining[]> {
        return this._httpBaseService.get('people/technical-specialists/training-list');
    }

      getTechnicalSpecialistDetail(): Observable<any> {
        return this._httpBaseService.post('people/technical-specialists/find-one', { "person_id_ts": this.selectedPersonIdTs });
    }

    setTsEditMode(e) {
        this.tsEdit = e;
        if (e) {
            this.ds_tsDetailClone = Object.assign({}, this.ds_tsDetail);
        } else {
            this.ds_tsDetail = Object.assign({}, this.ds_tsDetailClone);
            this.onCountryChange(this.ds_tsDetail.r_country_id);
        }
    }
    
    getTsStatuses() {
        this._lookupService.getTsStatuses().subscribe((result: any) => {
            this.ds_tsStatuses = result;
        });
      }

    //getCountries() {
    //    this._lookupService.countries().subscribe((result: any) => {
    //        this.ds_countries = result;
    //    });
    //  }
    
      onCountryChange(r_country_id: any) {
        this._lookupService.states(r_country_id).subscribe((result: any) => {
            this.ds_states = result;
            if (this.ds_tsDetail.r_country_id != r_country_id) {
                this._messagingService.sendMessage('country_changed');
                this.ds_tsDetail.r_state_or_county_id = 0;
                this.ds_tsDetail.state_abbr = null;
                this.ds_tsDetail.state_name = null;
            }
            this.ds_tsDetail.country_name = _.find(this.ds_countries, { id: r_country_id }).name;
            this.ds_tsDetail.country_abbr = _.find(this.ds_countries, { id: r_country_id }).abbr;
            this.ds_tsDetail.country_flag = _.find(this.ds_countries, { id: r_country_id }).country_flag.replace(' ', '-').toLowerCase();
            //this.ds_tsDetail.country_flag = _.find(this.ds_countries, { id: r_country_id }).country_flag.replaceAll(' ', '-').toLowerCase(); //ES2021+
        });
    

      }
    
      onStateChange(r_state_or_county_id) {
        if(r_state_or_county_id) {
            this.ds_tsDetail.state_name = _.find(this.ds_states, { id: r_state_or_county_id }).name;
            this.ds_tsDetail.state_abbr = _.find(this.ds_states, { id: r_state_or_county_id }).abbr;
        }
      }

      async updateTechnicalSpecialist(tsDetail: TsDetail) {
        this._httpBaseService.post('people/technical-specialists/update', tsDetail).subscribe((result: any) => {
            this._messagingService.sendMessage('ts_detail_updated');
        });
      
      }

    // getTechnicalSpecialistWorldActivity(): Observable<TechnicalSpecialistWorldActivity[]> {
    //     return this._httpBaseService.get('people/technical-specialists/world-activity');
    // }


}
