import { Injectable, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { EmtDialogComponent } from './emt-dialog.component';
import { EmtDialogOptions } from './_models/emt-dialog-options.model';

@Injectable({
  providedIn: 'root'
})
export class EmtDialogService {
  bsModalRef?: BsModalRef;
  modalRef?: BsModalRef;

  options: EmtDialogOptions = new EmtDialogOptions();

  constructor(private modalService: BsModalService) { }

  // openEmtDialogComponent(options: EmtDialogOptions) {
  //   this.options = options;
  //   this.bsModalRef = this.modalService.show(EmtDialogComponent);
  //   this.bsModalRef.content.closeBtnName = 'Close';
  // }

  openModal(template: TemplateRef<void>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.modalRef.hide();
  }

}                                                                                                                                                                                                                                                         
