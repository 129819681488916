<!-- <div class="n-container">
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <div class="card-body"> -->
                    <div class="grid">
                        <!-- <div class="col-12">
                            <page-header></page-header>
                        </div> -->
                        <div [ngSwitch]="headerService.display_sub_page" class="col-12">
                            
                            <!-- <customers *ngSwitchCase="'companies-customers'"></customers> -->
                            <viewer *ngSwitchCase="'report-detail'"></viewer>
                            <ng-container *ngSwitchDefault>
                                <ng-container *ngTemplateOutlet="dashboard"></ng-container>
                            </ng-container>
                        </div>
                    </div>
                <!-- </div> 
            </div>
    
        </div>
    </div>
</div> -->

<ng-template #dashboard>
    <div>
        <dashboard></dashboard>
    </div>
</ng-template>


