import { Component } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'reports-menu',
  template: `
    <span class="mr-1" *ngIf="'Pages' | permission">
          <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='reports-dashboard' || headerService.display_sub_page===''}" (click)="headerService.displaySubPage('reports-dashboard')" type="button">
              <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
              {{ 'Dashboard' | localize }}
          </button>
      </span>
  `,
  styles: ``
})
export class ReportsMenuComponent extends AppComponentBase {

}
