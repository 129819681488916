<div class="n-container">
    <!-- <form #candidateChecklist ngNoForm [formGroup]="dataService.selectedChecklist"ubmittForm()"> -->

        <div class="grid">
    
            <div class="col-12">
    
                <div class="grid">
    
                    <div class="col-4">
                        <div class="grid">
    
                            <div class="col-5">
                                <div class="emt-label">Delivery Type</div>
                            </div>
                            <div class="col-7">
                                <div class="emt-field">
                                    <dx-select-box [disabled]="_candidateService.controlsDisabled" id="delivery_type" [(ngModel)]="dataService.selectedChecklist.delivery_type"
                                        [items]="deliveryTypes" displayExpr="label" valueExpr="value" [value]="dataService.selectedChecklist.delivery_type">
                                    </dx-select-box>
                                </div>
                            </div>
    
                            <div class="col-5">
                                <div class="emt-label">Date Techpack Sent</div>
                            </div>
                            <div class="col-7">
                                <div class="emt-field">
                                    <dx-date-box [disabled]="_candidateService.controlsDisabled" type="date" id="date_techpack_sent" [(ngModel)]="dataService.selectedChecklist.date_techpack_sent"
                                        [value]="dataService.selectedChecklist.date_techpack_sent | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
    
                            
    
                        </div>
    
                    </div>

                    <div class="col-4">

                        <div class="grid">

                            <div class="col-5">
                                <div class="emt-label">Date of Birth</div>
                            </div>
                            <div class="col-7">
                                <div class="emt-field">
                                    <dx-date-box [disabled]="_candidateService.controlsDisabled" type="date" id="date_of_birth" [(ngModel)]="dataService.selectedChecklist.date_of_birth"
                                        [value]="dataService.selectedChecklist.date_of_birth | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
    
                            <div class="col-5">
                                <div class="emt-label">Social Security Number</div>
                            </div>
                            <div class="col-7">
                                <div class="emt-field">
                                    <dx-text-box [disabled]="_candidateService.controlsDisabled" id="social_security_number" [(ngModel)]="dataService.selectedChecklist.social_security_number"
                                        value="{{ dataService.selectedChecklist.social_security_number }}">
                                    </dx-text-box>
                                </div>
                            </div>
                            <!-- <div class="col-12">
                                <dx-button
                                    [disabled]="_candidateService.controlsDisabled"
                                    stylingMode="contained"
                                    text="Request TS Number"
                                    type="default"
                                    [width]="160"
                                    (onClick)="requestTsNumber($event)"
                                >
                                </dx-button>
                            </div>

                            <div class="col-5">
                                <div class="emt-label">TS number Requested</div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box [disabled]="_candidateService.controlsDisabled" id="ts_number_requested" [(ngModel)]="dataService.selectedChecklist.ts_number_requested"
                                        [value]="dataService.selectedChecklist.ts_number_requested"
                                        (onValueChanged)="setReceivedDate($event,'ts_number_requested')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box [disabled]="_candidateService.controlsDisabled" type="date" id="ts_number_requested_date"
                                        [(ngModel)]="dataService.selectedChecklist.ts_number_requested_date"
                                        [value]="dataService.selectedChecklist.ts_number_requested_date | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div> -->
                        </div>

                    </div>

                    <div class="col-4">

                        <div class="grid">

                            <div class="col-5">
                                <div class="emt-label">Certifications Verified</div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box [disabled]="_candidateService.controlsDisabled" id="certifications_verified" [(ngModel)]="dataService.selectedChecklist.certifications_verified"
                                        [value]="dataService.selectedChecklist.certifications_verified"
                                        (onValueChanged)="setReceivedDate($event,'certifications_verified')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box [disabled]="_candidateService.controlsDisabled" type="date" id="certifications_verified_date"
                                        [(ngModel)]="dataService.selectedChecklist.certifications_verified_date"
                                        [value]="dataService.selectedChecklist.certifications_verified_date | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>

                            <div class="col-5">
                                <div class="emt-label">Higher Education Verified</div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box [disabled]="_candidateService.controlsDisabled" id="higher_education_verified" [(ngModel)]="dataService.selectedChecklist.higher_education_verified"
                                        [value]="dataService.selectedChecklist.higher_education_verified"
                                        (onValueChanged)="setReceivedDate($event,'higher_education_verified')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box [disabled]="_candidateService.controlsDisabled" type="date" id="higher_education_verified_date"
                                        [(ngModel)]="dataService.selectedChecklist.higher_education_verified_date"
                                        [value]="dataService.selectedChecklist.higher_education_verified_date | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>

                            <ng-container *ngIf="dataService.selectedChecklist.higher_education_verified">
                                <div class="col-5">
                                    <div class="emt-label">How was Education Verified?</div>
                                </div>
                                <div class="col-7">
                                    <div class="emt-field">
                                        <dx-select-box [disabled]="_candidateService.controlsDisabled" id="how_higher_education_verified" [(ngModel)]="dataService.selectedChecklist.how_higher_education_verified"
                                            [items]="educationVerifyTypes" displayExpr="label" valueExpr="value" [value]="dataService.selectedChecklist.how_higher_education_verified">
                                        </dx-select-box>
                                    </div>
                                </div>

                                <div class="col-5">
                                    <div class="emt-label">University Representative Name</div>
                                </div>
                                <div class="col-7">
                                    <div class="emt-field">
                                        <dx-text-box [disabled]="_candidateService.controlsDisabled" id="university_representitive_name" [(ngModel)]="dataService.selectedChecklist.university_representitive_name"
                                            value="{{ dataService.selectedChecklist.university_representitive_name }}">
                                        </dx-text-box>
                                    </div>
                                </div>
                            </ng-container>

                        </div>

                    </div>
                    
                </div>
            </div>
    
            <div class="col-12 n-section-header">
                <h5>Documents Received</h5>
            </div>
    
            <div class="col-12">
                <div class="grid">
    
                    <div class="col-4">
                        <div class="grid">
        
                            <div class="col-5">
                                <div class="emt-label">Signed Application<i class="far fa-asterisk ml-1 text-danger text-sm" aria-hidden="true"></i></div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box [disabled]="_candidateService.controlsDisabled" id="signed_application_received" [(ngModel)]="dataService.selectedChecklist.signed_application_received"
                                        [value]="dataService.selectedChecklist.signed_application_received"
                                        (onValueChanged)="setReceivedDate($event,'signed_application_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box [disabled]="_candidateService.controlsDisabled" type="date" id="signed_application_received_date"
                                        [(ngModel)]="dataService.selectedChecklist.signed_application_received_date"
                                        [value]="dataService.selectedChecklist.signed_application_received_date | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                            <div class="col-5">
                                <div class="emt-label">Code of Ethics<i class="far fa-asterisk ml-1 text-danger text-sm" aria-hidden="true"></i></div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box [disabled]="_candidateService.controlsDisabled" id="code_of_ethics_received" [(ngModel)]="dataService.selectedChecklist.code_of_ethics_received"
                                        [value]="dataService.selectedChecklist.code_of_ethics_received"
                                        (onValueChanged)="setReceivedDate($event,'code_of_ethics_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box [disabled]="_candidateService.controlsDisabled" type="date" id="code_of_ethics_received_date"
                                        [(ngModel)]="dataService.selectedChecklist.code_of_ethics_received_date"
                                        [value]="dataService.selectedChecklist.code_of_ethics_received_date | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                            <div class="col-5">
                                <div class="emt-label">Non-Disclosure Agreement<i class="far fa-asterisk ml-1 text-danger text-sm" aria-hidden="true"></i></div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box [disabled]="_candidateService.controlsDisabled" id="nda_received" [(ngModel)]="dataService.selectedChecklist.nda_received"
                                        [value]="dataService.selectedChecklist.nda_received"
                                        (onValueChanged)="setReceivedDate($event,'nda_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box [disabled]="_candidateService.controlsDisabled" type="date" id="nda_received_date" [(ngModel)]="dataService.selectedChecklist.nda_received_date"
                                        [value]="dataService.selectedChecklist.nda_received_date | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                            <div class="col-5">
                                <div class="emt-label">Registration Agreement<i class="far fa-asterisk ml-1 text-danger text-sm" aria-hidden="true"></i></div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box [disabled]="_candidateService.controlsDisabled" id="registration_agreement_received"
                                        [(ngModel)]="dataService.selectedChecklist.registration_agreement_received"
                                        [value]="dataService.selectedChecklist.registration_agreement_received"
                                        (onValueChanged)="setReceivedDate($event,'registration_agreement_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box [disabled]="_candidateService.controlsDisabled" type="date" id="registration_agreement_received_date"
                                        [(ngModel)]="dataService.selectedChecklist.registration_agreement_received_date"
                                        [value]="dataService.selectedChecklist.registration_agreement_received_date | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                            <div class="col-5">
                                <div class="emt-label">Ethical Practices Agreement<i class="far fa-asterisk ml-1 text-danger text-sm" aria-hidden="true"></i></div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box [disabled]="_candidateService.controlsDisabled" id="ethical_practices_received" [(ngModel)]="dataService.selectedChecklist.ethical_practices_received"
                                        [value]="dataService.selectedChecklist.ethical_practices_received"
                                        (onValueChanged)="setReceivedDate($event,'ethical_practices_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box [disabled]="_candidateService.controlsDisabled" type="date" id="ethical_practices_received_date"
                                        [(ngModel)]="dataService.selectedChecklist.ethical_practices_received_date"
                                        [value]="dataService.selectedChecklist.ethical_practices_received_date | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                        </div>
        
        
                    </div>
                    <div class="col-4">
        
                        <div class="grid">
        
                            <div class="col-5">
                                <div class="emt-label">I9</div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box [disabled]="_candidateService.controlsDisabled" id="i9_received" [(ngModel)]="dataService.selectedChecklist.i9_received"
                                        [value]="dataService.selectedChecklist.i9_received"
                                        (onValueChanged)="setReceivedDate($event,'i9_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box [disabled]="_candidateService.controlsDisabled" type="date" id="i9_received_date" [(ngModel)]="dataService.selectedChecklist.i9_received_date"
                                        [value]="dataService.selectedChecklist.i9_received_date | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                            <div class="col-5">
                                <div class="emt-label">W4</div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box [disabled]="_candidateService.controlsDisabled" id="w4_received" [(ngModel)]="dataService.selectedChecklist.w4_received"
                                        [value]="dataService.selectedChecklist.w4_received"
                                        (onValueChanged)="setReceivedDate($event,'w4_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box [disabled]="_candidateService.controlsDisabled" type="date" id="w4_received_date" [(ngModel)]="dataService.selectedChecklist.w4_received_date"
                                        [value]="dataService.selectedChecklist.w4_received_date | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                            <div class="col-5">
                                <div class="emt-label">Notarized Support Documents</div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box [disabled]="_candidateService.controlsDisabled" id="support_docs_notarized" [(ngModel)]="dataService.selectedChecklist.support_docs_notarized"
                                        [value]="dataService.selectedChecklist.support_docs_notarized"
                                        (onValueChanged)="setReceivedDate($event,'support_docs_notarized')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box [disabled]="_candidateService.controlsDisabled" type="date" id="support_docs_notarized_date" [(ngModel)]="dataService.selectedChecklist.support_docs_notarized_date"
                                        [value]="dataService.selectedChecklist.support_docs_notarized_date | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                                
                            </div>
        
                            <div class="col-5">
                                <div class="emt-label">Resume</div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box [disabled]="_candidateService.controlsDisabled" id="resume_received" [(ngModel)]="dataService.selectedChecklist.resume_received"
                                        [value]="dataService.selectedChecklist.resume_received"
                                        (onValueChanged)="setReceivedDate($event,'resume_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box [disabled]="_candidateService.controlsDisabled" type="date" id="resume_received_date" [(ngModel)]="dataService.selectedChecklist.resume_received_date"
                                        [value]="dataService.selectedChecklist.resume_received_date | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                            <div class="col-5">
                                <div class="emt-label">Professional References</div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box [disabled]="_candidateService.controlsDisabled" id="i9_received" [(ngModel)]="dataService.selectedChecklist.professional_references_received"
                                        [value]="dataService.selectedChecklist.professional_references_received"
                                        (onValueChanged)="setReceivedDate($event,'professional_references_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box [disabled]="_candidateService.controlsDisabled" type="date" id="professional_references_received_date"
                                        [(ngModel)]="dataService.selectedChecklist.professional_references_received_date"
                                        [value]="dataService.selectedChecklist.professional_references_received_date | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                        </div>
        
        
        
                    </div>
                    <div class="col-4">
        
                        <div class="grid">
        
                            <div class="col-5">
                                <div class="emt-label">Previous Employment History</div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box [disabled]="_candidateService.controlsDisabled" id="previous_employment_history_received"
                                        [(ngModel)]="dataService.selectedChecklist.previous_employment_history_received"
                                        [value]="dataService.selectedChecklist.previous_employment_history_received"
                                        (onValueChanged)="setReceivedDate($event,'previous_employment_history_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box [disabled]="_candidateService.controlsDisabled" type="date" id="previous_employment_history_received_date"
                                        [(ngModel)]="dataService.selectedChecklist.previous_employment_history_received_date"
                                        [value]="dataService.selectedChecklist.previous_employment_history_received_date | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                            <div class="col-5">
                                <div class="emt-label">Direct Deposit</div>
                            </div>
                            <div class="col-1">
                                <div class="emt-field">
                                    <dx-check-box [disabled]="_candidateService.controlsDisabled" id="direct_deposit_form_received"
                                        [(ngModel)]="dataService.selectedChecklist.direct_deposit_form_received"
                                        [value]="dataService.selectedChecklist.direct_deposit_form_received"
                                        (onValueChanged)="setReceivedDate($event,'direct_deposit_form_received')"
                                        ></dx-check-box>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="emt-field">
                                    <dx-date-box [disabled]="_candidateService.controlsDisabled" type="date" id="direct_deposit_form_received_date"
                                        [(ngModel)]="dataService.selectedChecklist.direct_deposit_form_received_date"
                                        [value]="dataService.selectedChecklist.direct_deposit_form_received_date | date: 'yyyy-MM-dd'">
                                    </dx-date-box>
                                </div>
                            </div>
        
                            
        
                        </div>
        
                    </div>
    
                </div>
                
    
            </div>
    
        </div>
    
    <!-- </form> -->
</div>
