import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
@Component({
  selector: 'dcar-operations',
  standalone: true,
  imports: [CommonModule, ],
  templateUrl: './operations.component.html',
  styleUrl: './operations.component.css'
})
export class OperationsComponent {
  
}
