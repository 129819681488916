import { Injectable } from '@angular/core';
import { LookupsService } from '@app/lookups/lookups.service';
import { StaticDataService } from './static-data.service';
import { CandidateList } from '@app/dcar/candidate/_models/candidate-list.interface';
import { CandidateService } from '@app/dcar/candidate/candidate.service';
import { CandidateDetail } from '@app/dcar/candidate/_models/candidate-detail.interface';
import { CandidateChecklist } from '@app/dcar/candidate/_models/candidate-checklist.interface';
import { Observable } from 'rxjs';
import { HttpBaseService } from './http-base.service';
import { SignalService } from './signal.service';
import { DxDropDownButtonItemObject } from '../_types/dx-drop-down-button-item-object.type';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  countries: any[] = [];
  states: any[] = [];
  statuses: any[] = [];
  ds_candidateList: CandidateList[] = [];

  //selectedCandidateName: string = '';
  selectedCandidateId: number = 0;
  techPackRequestedByName: string = '';

  selectedCandidate: CandidateDetail // = new CandidateDetail();
  originalCandidate: CandidateDetail // = new CandidateDetail();
  
  selectedChecklist: CandidateChecklist // = new CandidateChecklist()
  originalChecklist: CandidateChecklist // = new CandidateChecklist()

  candidateOptionsMenuChoices: DxDropDownButtonItemObject[] = this._staticDataService.candidateOptionsMenu;

  constructor(
    private _httpBaseService: HttpBaseService,
    private _lookupsService: LookupsService, 
    private _staticDataService: StaticDataService, 
    private _candidateService: CandidateService,
    private _signalService: SignalService
  ) { 
      
      
    }

  getCountries() {
    this._lookupsService.getAllCountries().subscribe((result) => {
      this.countries = result;
    });
  }

  getStatuses() {
    this._staticDataService.getStatuses().subscribe((result) => {
      this.statuses = result;
    });
  }

  getStates(countryId) {
    //const countryId= this.countries.find(c => c.code === countryCode)?.id;
    this._lookupsService.states(countryId).subscribe((result) => {
      this.states = result;
      console.log(this.states);
    });
  }

  getCandidateList() {
    this._candidateService.getCandidateList().subscribe((result) => {
      this.ds_candidateList = result;
    });
  }

  getCandidateDetail(data: any) {
    console.log('data',data);
    this._signalService.openCandidateDetailPopup.set(true);
    
    this.callGetCandidateDetail(data).subscribe((result) => {
      this.selectedCandidate = result;
      
      if (this.selectedCandidate.tech_pack == 1) {
        this.candidateOptionsMenuChoices.find(c => c.value == 1).disabled = true;
      } else {
        this.candidateOptionsMenuChoices.find(c => c.value == 1).disabled = false;
      }

      this.getStates(result.country_id);
      this.techPackRequestedByName = result.requested_by_name != ''? result.requested_by_name : null;
    });

    this.callGetCandidateChecklist().subscribe((result) => {
      this.selectedChecklist = result? result : new CandidateChecklist();
    });
  }
  
  callGetCandidateDetail(data: any): Observable<CandidateDetail> {
    console.log('fired');
    const id = data.id;
    this.selectedCandidateId = id;
    //this.selectedCandidateName = `${data.last_name}, ${data.first_name}`;

    return this._httpBaseService.get(`dcar/get-candidate-detail/${id}`)
  }

  callGetCandidateChecklist(): Observable<CandidateChecklist> {
    return this._httpBaseService.post(`dcar/get-candidate-checklist`, {id: this.selectedCandidateId});
  }

  filterStateList(value) {
    console.log(value);
    this.getStates(value);
  }

  getPersonList() {
    return this._httpBaseService.get('dcar/get-person-list');
  }

  getDcarUserList() {
    return this._httpBaseService.get('dcar/get-dcar-user-list');
  }

  

}
