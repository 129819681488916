import { CommonModule } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DxHtmlEditorModule } from 'devextreme-angular';
import { CandidateService } from './candidate.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'dcar-candidate-resume',
  standalone: true,
  imports: [CommonModule, DxHtmlEditorModule, FormsModule],
  templateUrl: './candidate-resume.component.html',
  styleUrl: './candidate-resume.component.css'
})
export class CandidateResumeComponent extends AppComponentBase {
  constructor(
    injector: Injector,
    public _candidateService: CandidateService
    ) {
    super(injector);
  }

}
