import { Component } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'companies-menu',
  template: `
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='companies-dashboard' || headerService.display_sub_page===''}" (click)="headerService.displaySubPage('companies-dashboard')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'Dashboard' | localize }}
        </button>
    </span>
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='companies-customers'}" (click)="headerService.displaySubPage('companies-customers')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'Customers' | localize }}
        </button>
    </span>
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='companies-suppliers'}" (click)="headerService.displaySubPage('companies-suppliers')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'Suppliers' | localize }}
        </button>
    </span>
  `,
  styles: ``
})
export class CompaniesMenuComponent extends AppComponentBase {

}
