import { Component } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'programs-menu',
  template: `
    
    <span class="mr-1" *ngIf="'Pages' | permission">
        <sl-button size="large" style="width:110px;" variant="default" outline (click)="headerService.displaySubPage('programs-dashboard')">
            <sl-icon slot="prefix" name="list-ul"></sl-icon>
            {{ 'Dashboard' | localize }}
        </sl-button>
    </span>
    <!-- <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="btn btn-secondary" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='programs-dashboard' || headerService.display_sub_page===''}" (click)="headerService.displaySubPage('programs-dashboard')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'Dashboard' | localize }}
        </button>
    </span> -->
    <span class="mr-1" *ngIf="'Pages.Programs.Collins' | permission">
        <sl-button size="large" style="width:110px;" variant="default" outline (click)="headerService.displaySubPage('programs-collins')">
            <sl-icon slot="prefix" name="list-ul"></sl-icon>
            {{ 'Collins' | localize }}
        </sl-button>
        <!-- <button class="btn btn-secondary"  [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='programs-collins'}" (click)="headerService.displaySubPage('programs-collins')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'Collins' | localize }}
        </button> -->
    </span>
    <span class="mr-1" *ngIf="'Pages.Programs.Ge' | permission">
        <sl-button size="large" style="width:110px;" variant="default" outline (click)="headerService.displaySubPage('programs-ge')">
            <sl-icon slot="prefix" name="list-ul"></sl-icon>
            {{ 'GE' | localize }}
        </sl-button>
        <!-- <button class="btn btn-secondary"  [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='programs-ge'}" (click)="headerService.displaySubPage('programs-ge')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'GE' | localize }}
        </button> -->
    </span>
    <span class="mr-1" *ngIf="'Pages.Programs.Lmc' | permission">
        <sl-button size="large" style="width:110px;" variant="default" outline (click)="headerService.displaySubPage('programs-lmc')">
            <sl-icon slot="prefix" name="list-ul"></sl-icon>
            {{ 'LMC' | localize }}
        </sl-button>
        <!-- <button class="btn btn-secondary"  [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='programs-lmc'}" (click)="headerService.displaySubPage('programs-lmc')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'LMC' | localize }}
        </button> -->
    </span>
    <span class="mr-1" *ngIf="'Pages.Programs.Parker' | permission">
        <sl-button size="large" style="width:110px;" variant="default" outline (click)="headerService.displaySubPage('programs-parker')">
            <sl-icon slot="prefix" name="list-ul"></sl-icon>
            {{ 'Parker' | localize }}
        </sl-button>
        <!-- <button class="btn btn-secondary"  [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='programs-parker'}" (click)="headerService.displaySubPage('programs-parker')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'Parker' | localize }}
        </button> -->
    </span>
    <span class="mr-1" *ngIf="'Pages.Programs.Pratt' | permission">
        <sl-button size="large" style="width:110px;" variant="default" outline (click)="headerService.displaySubPage('programs-pratt')">
            <sl-icon slot="prefix" name="list-ul"></sl-icon>
            {{ 'Pratt' | localize }}
        </sl-button>
        <!-- <button class="btn btn-secondary"  [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='programs-pratt'}" (click)="headerService.displaySubPage('programs-pratt')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'Pratt' | localize }}
        </button> -->
    </span>
    <span class="mr-1" *ngIf="'Pages.Programs.Sqac' | permission">
        <sl-button size="large" style="width:110px;" variant="default" outline (click)="headerService.displaySubPage('programs-sqac')">
            <sl-icon slot="prefix" name="list-ul"></sl-icon>
            {{ 'SQAC' | localize }}
        </sl-button>
        <!-- <button class="btn btn-secondary"  [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='programs-sqac'}" (click)="headerService.displaySubPage('programs-sqac')" type="button">
            <fa-icon [icon]="icon.farList" class="mr-1"></fa-icon>
            {{ 'SQAC' | localize }}
        </button> -->
    </span>
  `,
  styles: ``
})
export class ProgramsMenuComponent extends AppComponentBase {

}
