import { Component } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'payroll-validation-menu',
    template: `
        <span class="mr-1" *ngIf="'Pages' | permission">
            <button class="button-13" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_sub_page==='payroll-validation-missing-employee-codes'}" (click)="headerService.displaySubSubPage('payroll-validation-missing-employee-codes')" type="button">
                {{ 'Missing Employee Codes' | localize }}
            </button>
        </span>
    `,
    styles: ``,
})
export class PayrollValidationMenuComponent extends AppComponentBase {}
