import { Component } from '@angular/core';
import {CommonModule} from '@angular/common';
import { ReportsDxComponent } from '@app/reports-dx/reports-dx.component';
import { PivotsComponent } from "../../pivots/pivots.component";

@Component({
  selector: 'dcar-employee',
  standalone: true,
  imports: [CommonModule, ReportsDxComponent, PivotsComponent],
  templateUrl: './employee.component.html',
  styleUrl: './employee.component.css'
})
export class EmployeeComponent {

}
