import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'dcar-admin',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.css'
})
export class AdminComponent {

}
