import { Injectable, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
//import { HttpBaseService } from './http-base.service';
//import { ViewerComponent } from '@app/report/viewer/viewer.component';
//import { ReportList } from '@app/_model/report-list.model';
import { Observable } from 'rxjs';
import { ViewerComponent } from './viewer/viewer.component';
import { HttpBaseService } from '@app/shared/_services/http-base.service';

@Injectable({
    providedIn: 'root'
})
export class ReportsDxService {
    @ViewChild('viewer') viewer: ViewerComponent;

    repName: string = 'R021';
    p0: string = '1100046';
    p1: string = '1100046';
    p2: string;
    
    reportName: string;

    constructor(private _httpBaseService: HttpBaseService, private router: Router) { }

    getReportList(): Observable<ReportList[]> {
        const apiUrl = 'reports/list';
        return this._httpBaseService.get(apiUrl);
        //return this.http.get<ReportList[]>(environment.nApi + 'report/list', { headers: environment.nApiHeaders });
    }





    // setReportName(e): void {
    //     this.reportName = e;
        
    //     this.router.navigateByUrl('/app/report/viewer');
    // }
}
