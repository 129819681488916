import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CandidateService } from './candidate.service';
import { EditorModule } from 'primeng/editor';
import { LookupsService } from '@app/lookups/lookups.service';
import { DxCheckBoxModule, DxDateBoxModule, DxHtmlEditorModule, DxPopupModule, DxSelectBoxModule, DxTextAreaModule, DxTextBoxModule } from 'devextreme-angular';
import { CandidateDetail } from './_models/candidate-detail.interface';
import { StaticDataService } from '@app/shared/_services/static-data.service';
import { DxButtonTypes } from 'devextreme-angular/ui/button';
import { DataService } from '@app/shared/_services/data.service';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'dcar-candidate-detail',
  standalone: true,
  imports: [
            CommonModule, 
            FormsModule, 
            ReactiveFormsModule, 
            EditorModule, 
            DxTextBoxModule, 
            DxCheckBoxModule,
            DxTextAreaModule,
            DxHtmlEditorModule,
            DxDateBoxModule,
            DxSelectBoxModule,
            DxPopupModule
          ],
  templateUrl: './candidate-detail.component.html',
  styleUrl: './candidate-detail.component.css',
  //schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CandidateDetailComponent extends AppComponentBase {
  @ViewChild('candForm') candidateDetailForm
  //@Output() passCloseUpdatePopup = new EventEmitter<boolean>();

  
  // countries: any[] = [];
  // states: any[] = [];
  // statuses: any[] = [];
  
  constructor(
    injector: Injector,
    public _candidateService: CandidateService,
    private _lookupsService: LookupsService,
    private _staticDataService: StaticDataService,
      ) {
    super(injector);
    this.dataService.selectedCandidate = new CandidateDetail();
    this.dataService.originalCandidate = new CandidateDetail();
    // this.dataService.selectedCandidate.country valueChanges.subscribe((value) => {
    //   console.log('value',value);
    //   this._dataService.getStates(value);
    // });
    
  }

  ngOnInit(): void {

    this.dataService.getCountries();
    this.dataService.getStatuses();

    
    
    // this._lookupsService.getAllCountries().subscribe((result) => {
    //   this.countries = result;
    // });

    // this._staticDataService.getStatuses().subscribe((result) => {
    //   this.statuses = result;
    // });
  }

  

  // getStates(countryCode) {
  //   //const countryCode = this._candidateService.candidateDetailForm.get('country')?.value;
  //   const countryId= this.countries.find(c => c.code === countryCode)?.id;
    
  //   this._lookupsService.states(countryId).subscribe((result) => {
  //     this.states = result;
  //     console.log(this.states);
  //   });
  // }

  //onSubmit(): void {
    //console.log(this._candidateService.candidateDetailForm.value);
  //}

  

  toggleDiabled() {
    this._candidateService.controlsDisabled = !this._candidateService.controlsDisabled
  }

  edit() {
    if (this._candidateService.controlsDisabled) {
      this.dataService.originalCandidate = this.deepCopy(this.dataService.selectedCandidate);
    } else {
      this.dataService.selectedCandidate = this.deepCopy(this.dataService.originalCandidate);
    }
    this._candidateService.controlsDisabled = !this._candidateService.controlsDisabled
  }


  deepCopy(candidate: any): any {
    return JSON.parse(JSON.stringify(candidate));
  }

  updateCandidate() {
    //this.candidateDetailForm.nativeElement.submit();
    this._candidateService.updateCandidate(this.dataService.selectedCandidate).subscribe((result) => {
      if (result.status ='success') {
        this.notify.success(this.l('SavedSuccessfully'));
        this.dataService.selectedCandidate = new CandidateDetail();
        this.dataService.originalCandidate = new CandidateDetail();
        this._candidateService.closeUpdatePopup(result);
        this._candidateService.controlsDisabled = true;
        this.dataService.getCandidateList();
      } else {
        this.message.error('Error', 'Error saving candidate');
      }

    });
  }

  //closeParentPopup() {
  //  this.passCloseUpdatePopup.emit(true);
  //}

  requestTsNumber(e) {
    console.log(e);
  }
}
