import {Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {AppComponentBase} from "@shared/common/app-component-base";
import {appModuleAnimation} from "@shared/animations/routerTransition";
import {DxReportViewerModule} from "@node_modules/devexpress-reporting-angular";
import { UtilsModule } from '@shared/utils/utils.module';
import { ReportsDxService } from '../reports-dx.service';
import { fetchSetup } from '@devexpress/analytics-core/analytics-utils';


@Component({
    selector: 'viewer',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    templateUrl: './viewer.component.html',
    styleUrls: ['./viewer.component.css',
        '../../../../node_modules/devextreme/dist/css/dx.common.css',
        '../../../../node_modules/devextreme/dist/css/dx.light.css',
        '../../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css',
        '../../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css',
        '../../../../node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css'],
    imports: [DxReportViewerModule, UtilsModule],
    animations: [appModuleAnimation()]
})
export class ViewerComponent extends AppComponentBase implements OnInit {

    title = this._reportsDxService.repName;
    reportUrl = this._reportsDxService.repName;
    hostUrl = 'https://localhost:44303/';
    invokeAction = 'DXXRDV';
    //serverUrl = this.hostUrl + this.invokeAction;

    constructor(
        injector: Injector,
        private _reportsDxService: ReportsDxService
    ) {
        super(injector);
        // fetchSetup.fetchSettings = {
        //     headers: { 'Authorization': abp.auth.getToken() },
        //     //the void return type was eabp.auth.getToken() },with Promise starting v24.1.4+
        //     beforeSend: (requestParameters) => {
        //         requestParameters.credentials = 'include';
        //         }
        //   };
    }

    ngOnInit(): void {
    }
}