import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DxDropDownButtonItemObject } from '../_types/dx-drop-down-button-item-object.type';
import { StaticLookup } from '../_models/static-lookup.model';


@Injectable({
  providedIn: 'root'
})
export class StaticDataService {

  constructor() { }

  getDeliveryTypes(): StaticLookup[] {
    return [
      { value: 'EMail', label: 'EMail' },
      { value: 'Regular Mail-USPS', label: 'Regular Mail-USPS' },
      { value: '2 Day', label: '2 Day' },
      { value: '3 Day', label: '3 Day' },
      { value: 'Standard Overnight', label: 'Standard Overnight' },
      { value: 'Priority Overnight', label: 'Priority Overnight' }
    ];
  }
  
  educationVerifyTypes(): StaticLookup[] {
    return [
      { value: 'Objective Evidence in file', label: 'Objective Evidence in file' },
      { value: 'By University', label: 'By University' }
    ];
  }

  getStatuses(): Observable<StaticLookup[]> {
    const statuses = [
      { value: 1, label: 'Not Reviewed' },
      { value: 2, label: 'Accepted-Not Assigned' },
      { value: 3, label: 'Accepted-Assigned' },
      { value: 4, label: 'Out of Scope' }
    ];
    return of(statuses);
  }

  get candidateOptionsMenu(): DxDropDownButtonItemObject[] {
    return [
      {value: 1, name: 'Request Tech Pack', icon: 'fas fa-user', disabled: false},
      {value: 2, name: 'Convert to TS', icon: 'fas fa-user', disabled: false},
    ];
  }

  deliveryTypes: StaticLookup[] =
    [
      { value: 1, label: 'EMail' },
      { value: 2, label: 'Regular Mail-USPS' },
      { value: 3, label: '2 Day' },
      { value: 4, label: '3 Day' },
      { value: 5, label: 'Standard Overnight' },
      { value: 6, label: 'Priority Overnight' }
    ];
  
  
}

    