import { Component, Injector, OnInit } from '@angular/core';
import { ReportList } from '../_models/report-list.model';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { ReportsDxService } from '../reports-dx.service';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
//import { ReportViewerService } from '../report-viewer.service';

@Component({
  selector: 'dashboard',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css',
  animations: [appModuleAnimation()]
})
export class DashboardComponent extends AppComponentBase implements OnInit {

  reportList: ReportList[] = [];
  reportCategories: ReportList[] = [];

  block = `<div [class]="'n-container'">
  <div class="p-d-flex p-flex-wrap">
      <div class="grid">
          <div *ngFor="let cat of reportCategories" class="col-2">
              <div class="p-mr-2 p-mb-2">
                  <div class="card card-custom gutter-b">
                      <div class="card-header align-items-center border-0">
                          <h3 class="card-title align-items-start flex-column">
                              <span class="font-weight-bolder text-dark">
                                  {{ cat.ReportCategory }}
                              </span>
                          </h3>
                      </div>
                      <div class="card-body">
                          <div class="grid">
                              <ng-container *ngFor="let rep of reportList">
                                  <div
                                      *ngIf="rep.rReportCategory_Id == cat.rReportCategory_Id"
                                      class="col-12 rpt-item"
                                  >
                                      <ng-container *ngIf="rep.ReportStatus == 'inactive'">
                                          <fa-icon
                                              [icon]="icon.farBan"
                                              class="n-color-red"
                                              style="font-size: 16px"
                                          ></fa-icon>
                                          &nbsp;&nbsp;
                                          <span class="n-color-blue">{{ rep.ReportName }}</span>
                                      </ng-container>
                                      <ng-container *ngIf="rep.ReportStatus == 'active'">
                                          <a class="n-abtn" (click)="setReportName(rep.ReportCode)">
                                              <fa-icon
                                                  [icon]="icon.farFileInvoice"
                                                  class="n-color-green"
                                                  style="font-size: 16px"
                                              ></fa-icon>
                                          </a>
                                          &nbsp;&nbsp;
                                          <a class="n-abtn n-color-blue" (click)="setReportName(rep.ReportCode)">
                                              {{ rep.ReportName }}
                                          </a>
                                      </ng-container>
                                  </div>
                              </ng-container>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>`

  constructor(injector: Injector,
      private _reportsDxService: ReportsDxService,
      //private _viewerService: ReportViewerService
  ) {
      super(injector);
  }

  ngOnInit(): void {
      this.getReportList();
  }

  getReportList() {
      new Promise((resolve, reject) => {
          this._reportsDxService.getReportList().subscribe(res => {
              resolve(res);
          })
      }).then((result: []) => {
          this.reportList = result;
          let category: string = '';
          this.reportList.forEach((item) => {
              if (item.report_category !== category) {
                  category = item.report_category;
                  this.reportCategories.push(item);
              }
          }) ;
          setTimeout(() => {
          }, 2000);
      }).catch(() => {

      })
  }

  setReportName(e) {
    console.log(e);
      this._reportsDxService.repName = e;
      this.headerService.display_sub_page = 'report-detail'
      //this._reportsService.setReportName(e);
  }

  //setReportName(e): void {
  //  this.reportName = e;
  //  
  //  this.router.navigateByUrl('/app/report/viewer');
  //}
}
